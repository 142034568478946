import { Box, Paper, Typography, Stack } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavBar from "./OldNavBar";
import Carousel from "react-material-ui-carousel";
import { useState } from "react";

export default function KnowledgeBasePg() {

  const [iconId, setIconId] = useState(100);
  let clickCount = 0;

  const handleIconClick = (id) => () => {
    setIconId(id);
    // clickCount++;
    // if (id === 101 && clickCount === 1)
    //   setIconId(101)
    // else{
    //  setIconId(100);
    //  clickCount = 0;
    // }
  };

  const items = [
    {
      name: "Item 1",
      description: "This is the first item in the carousel.",
      image: require("../images/solar1.jpg"),
    },
    {
      name: "Item 2",
      description: "This is the second item in the carousel.",
      image: require("../images/solar2.jpg"),
    },
    {
      name: "Item 3",
      description: "This is the third item in the carousel.",
      image: require("../images/solar3.jpg"),
    },
  ];

  function Item(props) {
    return (
      <Paper sx={{ justifyItems: "center", width: "100%" }}>
        <img
          src={props.item.image}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          alt="solar"
        />
      </Paper>
    );
  }

  return (
    <>
      <Box>
      <NavBar cartDisplayProp={'show'}
              showMenuBarProp={'show'}
      />
        <Carousel height={250} sx={{ backgroundColor: "green" }}>
          {items.map((item, i) => (
            <Item key={i} item={item} />
          ))}
        </Carousel>
      </Box>

      <Box m={5} display="flex"
        maxWidth="80%">

        <Stack spacing={2}>
          <p>
            <Typography variant="h5" align="left"
              textAlign="center"><b>Basic Understanding & Various Definitions </b>
            </Typography>
          </p>

          <Accordion>
            <AccordionSummary
              // expandIcon={iconId === 100 ?  <AddIcon color="success"/> : <RemoveIcon color="success"/>}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              onClick={handleIconClick(101)}
            >
              <Typography><b>Elements of an Atom</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                All matter is composed of molecules which are made up of a combination of atoms. Atoms have a nucleus with electrons orbiting around it. The nucleus is composed of protons and neutrons (not shown). Most atoms have an equal number of electrons and protons. Electrons have a negative charge (-). Protons have a positive charge (+). Neutrons are neutral. The negative charge of the electrons is balanced by the positive charge of the protons.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              // expandIcon={iconId === 100 ?  <AddIcon color="success"/> : <RemoveIcon color="success"/>}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              onClick={handleIconClick(101)}
            >
              <Typography><b>Free Electrons</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Electrons in the outer band can become free of their orbit by the application of some external force such as movement through a magnetic field, friction, or chemical action. These are referred to as free electrons. A free electron leaves a void which can be filled by an electron forced out of orbit from another atom. As free electrons move from one atom to the next an electron flow is produced. This is the basis of electricity.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              // expandIcon={iconId === 100 ?  <AddIcon color="success"/> : <RemoveIcon color="success"/>}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              onClick={handleIconClick(101)}
            >
              <Typography><b>Neutral State of an Atom</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Elements are often identified by the number of electrons in orbit around the nucleus of the atoms making up the element and by the number of protons in the nucleus. A hydrogen atom, for example, has only one electron and one proton. An aluminum atom has 13 electrons and 13 protons. An atom with an equal number of electrons and protons is said to be electrically neutral.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              // expandIcon={iconId === 100 ?  <AddIcon color="success"/> : <RemoveIcon color="success"/>}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              onClick={handleIconClick(101)}
            >
              <Typography><b>Positive and Negative Charges </b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                A positive or negative charge is caused by an absence or excess of electrons. The number of protons remains constant.
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion>
            <AccordionSummary
              // expandIcon={iconId === 100 ?  <AddIcon color="success"/> : <RemoveIcon color="success"/>}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              onClick={handleIconClick(101)}
            >
              <Typography><b>What is electricity? </b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Electricity is a type of energy that can build up in one place or flow from one place to another. When electricity gathers in one place it is known as static electricity (the word static means something that does not move); electricity that moves from one place to another is called current electricity.
                Static electricity and current electricity are like potential energy and kinetic energy. When electricity gathers in one place, it has the potential to do something in the future. Electricity stored in a battery is an example of electrical potential energy.

              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              // expandIcon={iconId === 100 ?  <AddIcon color="success"/> : <RemoveIcon color="success"/>}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              onClick={handleIconClick(101)}
            >
              <Typography><b>What is an electric current? </b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                When electrons move, they carry electrical energy from one place to another. This is called current electricity or an electric current.
                An electric current is a flow of electrons. Current flows through a circuit when there's a difference in potential energy between one end of a conductor and the other.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              // expandIcon={iconId === 100 ?  <AddIcon color="success"/> : <RemoveIcon color="success"/>}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              onClick={handleIconClick(101)}
            >
              <Typography><b>How is current measured?</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Current is measured in amps, and this measurement is taken by calculating the amount of charge that flows through one point in a circuit. In electrical equations, a current is indicated using the letter "I."
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              // expandIcon={iconId === 100 ?  <AddIcon color="success"/> : <RemoveIcon color="success"/>}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              onClick={handleIconClick(101)}
            >
              <Typography><b>Calculating current</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Current can be calculated using Ohm's Law. The equation for Ohm's Law is I = V/R, where I is the current, V is the voltage, and R is the resistance. This equation can also be used to figure out the resistance of a circuit or the voltage of a circuit if the other factors are known.
                Current can also be used to calculate the power using the equation P = I * V, where P is power, I is current, and V is voltage.

              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              // expandIcon={iconId === 100 ?  <AddIcon color="success"/> : <RemoveIcon color="success"/>}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              onClick={handleIconClick(101)}
            >
              <Typography><b>Electric circuits</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                For an electric current to happen, there must be a circuit. A circuit is a closed path or loop around which an electric current flows.

              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              // expandIcon={iconId === 100 ?  <AddIcon color="success"/> : <RemoveIcon color="success"/>}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              onClick={handleIconClick(101)}
            >
              <Typography><b>How electricity moves in a circuit</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Materials such as copper metal that conduct electricity (allow it to flow freely) are called conductors. Materials that don't allow electricity to pass through them so readily, such as rubber and plastic, are called insulators.

              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              // expandIcon={iconId === 100 ?  <AddIcon color="success"/> : <RemoveIcon color="success"/>}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              onClick={handleIconClick(101)}
            >
              <Typography><b>What makes copper a conductor and rubber an insulator?</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                A current of electricity is a steady flow of electrons. When electrons move from one place to another, round a circuit, they carry electrical energy from place to place like marching ants carrying leaves. Instead of carrying leaves, electrons carry a tiny amount of electric charge.
                Electricity can travel through something when its structure allows electrons to move through it easily. Metals like copper have "free" electrons that are not bound tightly to their parent atoms. These electrons flow freely throughout the structure of copper and this is what enables an electric current to flow. In rubber, the electrons are more tightly bound. There are no "free" electrons and, as a result, electricity does not really flow through rubber at all.


              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              // expandIcon={iconId === 100 ?  <AddIcon color="success"/> : <RemoveIcon color="success"/>}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              onClick={handleIconClick(101)}
            >
              <Typography><b>Voltage</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Electrons are negative and are attracted by positive charges. They will always be attracted from a source having an excess of electrons, thus having a negative charge, to a source having a deficiency of electrons which has a positive charge. The force required to make electicity flow through a conductor is called a difference in potential, electromotive force (emf), or more simply referred to as voltage. voltage is designated by the letter “E”, or the letter “V”. The unit of measurement for voltage is volts which is also designated by the letter “V”.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              // expandIcon={iconId === 100 ?  <AddIcon color="success"/> : <RemoveIcon color="success"/>}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              onClick={handleIconClick(101)}
            >
              <Typography><b>Resistance</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Resistance is a measure of the opposition to current flow in an electrical circuit.
                Resistance is measured in ohms, symbolized by the Greek letter omega (Ω). All materials resist current flow to some degree.
                They fall into one of two broad categories:
                <ul>
                  <li><b>Conductors:</b> Materials that offer very little resistance where electrons can move easily. Examples: silver, copper, gold and aluminum.</li>
                  <li><b>Insulators: </b>Materials that present high resistance and restrict the flow of electrons. Examples: Rubber, paper, glass, wood and plastic.</li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

        </Stack>
      </Box>
    </>
  );
}