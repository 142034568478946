import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Carousel from "react-material-ui-carousel";
// import HomePgComp from "../components/HomePgComp";
import NavBar from "./OldNavBar";
import { Divider } from "@mui/material";
import PageFooter from "../footer/PageFooter";
import BreadCrumbs from "../components/breadCrumbs";
import NavBar2 from "../components/Navbar2";
import NewHomePgComp2 from "../components/NewHomePgComp2";

export default function Home() {
  const items = [
    {
      name: "Rooftop Solar",
      description: "Rooftop Solar plants and solutions",
      image: require("../images/CarousalImgs/Solar.jpg"),
    },
    {
      name: "Battery",
      description: "Battery - LeadAcid and Lithium - Entergy storage sysems",
      image: require("../images/CarousalImgs/Battery.jpg"),
    },
    {
      name: "UPS and Inverter",
      description: "UPS and Inverters for power backup",
      image: require("../images/CarousalImgs/UPS.jpg"),
    },
    {
      name: "Digital Commerce",
      description: "Digital Ecommerce and IoT solutions",
      image: require("../images/CarousalImgs/ecommerce.jpg"),
    },
    {
      name: "Energy Conservation",
      description: "Energy Conservation solutions and automation",
      image: require("../images/CarousalImgs/EnergyConservation.jpg"),
    },
    {
      name: "Consulting and Audit",
      description: "Consulting and Audit for Green Energy",
      image: require("../images/CarousalImgs/Consulting.jpg"),
    },
  ];

  function Item(props) {
    return (
      <Paper sx={{ justifyItems: "center", width: "100%" }}>
        <img
          src={props.item.image}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          alt="solar"
        />
      </Paper>
    );
  }

  return (
    <>
    <Box>
      
      <NavBar2 cartDisplayProp={'none'}
              showMenuBarProp={'show'}
            />
      

      <Carousel height={250} sx={{ backgroundColor: "green" }}>
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>
      
      <br/>
      <Divider sx={{bgcolor:"green", borderBottomWidth: '2px'}}></Divider>
      {/* Paper and cards displayed on Home page are part of below component */}
      {/* <HomePgComp /> */}
      <NewHomePgComp2 />
      {/* <BreadCrumbs/> */}
    </Box>
    {/* <br/>
    <Divider sx={{bgcolor:"green", borderBottomWidth: '10px'}}></Divider> */}
    <br/>
    <PageFooter/>
    <br/>
    </>
  );
}
