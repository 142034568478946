import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper, Snackbar, TextField, Alert } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormControl} from "@mui/material";
import { UpdateOrder, UpdateOrderStatus } from "../../DatabaseAPI/DatabaseQuery";

const ItemDrawer = ({
	open,
	onClose,
	item
}) => {
	const statusMapping = {
		1: "Received",
		2: "Confirmed",
		3: "In Process",
		4: "Completed",
		10: "Cancelled",
		11: "On-Hold",
	};

	const [selectedStatus, setSelectedStatus] = useState("");
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("success");
	const [editMode] = useState(false);
	const [editableItem, setEditableItem] = useState({});

	//Handling Status change by Ops and Admin
	const handleStatusChange = async (itemId, status) => {
		try {
      console.log("Item", itemId, "status", status);
      status = parseInt(status, 10);
      // console.log("Item", itemId, "status", status);
			await UpdateOrderStatus(itemId, status);//Function in Database query to handle Updation 
			setSelectedStatus(status);
			setSnackbarOpen(true);
			setSnackbarMessage("Order status updated successfully");
		} catch (error) {
			setSnackbarOpen(true);
			setSnackbarMessage("Error updating order status");
			setSnackbarSeverity("warning");
			// console.error("Error updating order status: ", error);
		}
	};

	useEffect(() => {
		if (item) {
			console.log("Item", item);
			setSelectedStatus(item.ordOverallStatus);
			// console.log("Selected Status", selectedStatus);
			setEditableItem({ ...item });
		}
	}, [item,selectedStatus]);

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};

	// const handleEditClick = () => {
	// 	setEditMode(true);
	// };

	// const handleSaveClick = async () => {
	// 	setEditMode(false);
  //   UpdateOrder(item.id, editableItem);
	// 	setSnackbarOpen(true);
	// 	setSnackbarMessage("Order details updated successfully");
	// 	setSnackbarSeverity("success");
	// 	// console.log("Updated item details Falsely: ", editableItem);
	// };
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setEditableItem((prev) => ({ ...prev, [name]: value }));
		// console.log("Item id:",item.id,"Updated item details: ", editableItem);
	};
  

	return (
		<Drawer
			anchor="bottom"
			open={open}
			onClose={onClose}
			PaperProps={{
				style: {
					height: "55%",
					padding: 20,
				},
			}}
		>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					position: "relative",
				}}
			>
				<Typography variant="h6" style={{ flex: 1, textAlign: "left" }}>
					Item Details
				</Typography>


				<IconButton onClick={onClose} aria-label="Close drawer">
					<CloseIcon />
				</IconButton>
			</div>

			{item ? (
				<div style={{ marginTop: 20 }}>
					<Paper elevation={3} sx={{ padding: 3, marginBottom: 2 }}>
						<Grid container spacing={2}>

							<Grid item xs={12} sm={6}>
									<Typography>
										<strong>Order ID:</strong> {item.ordID}
									</Typography>
								
							</Grid>

							<Grid item xs={12} sm={6}>
								{editMode ? (
									<TextField
										fullWidth
										label="Email ID"
										name="ordEmailID"
										value={editableItem.ordEmailID}
										onChange={handleInputChange}
									/>
								) : (
									<Typography>
										<strong>Email ID:</strong> {item.ordEmailID}
									</Typography>
								)}
							</Grid>
							<Grid item xs={12} sm={6}>
								{editMode ? (
									<TextField
										fullWidth
										label="Customer Phone Number"
										name="ordPhoneNumber"
										value={editableItem.ordPhoneNumber}
										onChange={handleInputChange}
									/>
								) : (
									<Typography>
										<strong>Customer Phone Number:</strong> {item.ordPhoneNumber}
									</Typography>
								)}
							</Grid>

							<Grid item xs={12} sm={6}>
								{editMode ? (
									<TextField
										fullWidth
										label="Customer Name"
										name="ordUsrName"
										value={editableItem.ordUsrName}
										onChange={handleInputChange}
									/>
								) : (
									<Typography>
										<strong>Customer Name:</strong> {item.ordUsrName}
									</Typography>
								)}
							</Grid>

							<Grid item xs={12} sm={6}>
								{editMode ? (
									<TextField
										fullWidth
										label="Product Name"
										name="ordProdName"
										value={editableItem.ordProdName}
										onChange={handleInputChange}
									/>
								) : (
									<Typography>
										<strong>Product Name:</strong> {item.ordProdName}
									</Typography>
								)}
							</Grid>

              <Grid item xs={12} sm={6}>
                <Typography><strong>Order PinCode:</strong> {item.ordPinCode}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography><strong>Order Reference Number:</strong> {item.ordReferenceID}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography><strong>Delivery Address:</strong> {item.ordCustomerDeliveryAddress}</Typography>
              </Grid>

							<Grid item xs={12} sm={6}>
								{editMode ? (
									<TextField
										fullWidth
										label="Order Quantity"
										name="ordQty"
										value={editableItem.ordQty}
										onChange={handleInputChange}
									/>
								) : (
									<Typography>
										<strong>Order Quantity:</strong> {item.ordQty}
									</Typography>
								)}
							</Grid>

							<Grid item xs={12} sm={6}>
								{editMode ? (
									<TextField
										fullWidth
										label="Order Sub Total"
										name="ordTotalAmountPayable"
										value={editableItem.ordTotalAmountPayable}
										onChange={handleInputChange}
									/>
								) : (
									<Typography>
										<strong>Order Sub Total:</strong> {item.ordTotalAmountPayable}
									</Typography>
								)}
							</Grid>

							{/* Repeat the same pattern for other fields */}

							<Grid item xs={12} sm={6}>
								<FormControl fullWidth>
									<Typography>
										<strong>Order Status:</strong>
									</Typography>
									<Select
										labelId="order-status-label"
										variant="standard"
										value={selectedStatus}
										onChange={(e) =>
											handleStatusChange(item.id, e.target.value)
										}
										label="Order Status"
									>
										{Object.keys(statusMapping).map((key) => (
											// console.log("Key is : ",key)
											<MenuItem key={key} value={key}>
												{statusMapping[key]}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Paper>
					<div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
						{/* {editMode ? ( */}
							{/* <Button
								variant="contained"
								color="primary"
								onClick={handleSaveClick}
							>
								Save
							</Button> */}
						{/* ) : (
							<IconButton
								variant="outlined"
								color="primary"
								onClick={handleEditClick}
								size="small"
								style={{
									borderRadius: "50%",
									width: 40,
									height: 40,
									padding: 8,
								}}
								sx={{ "&:hover": { backgroundColor: "grey", color: "white" } }}
								aria-label={`Edit item ${item.id}`}
							>
								<EditIcon fontSize="small" />
							</IconButton> */}

					</div>
				</div>
			) : (
				<Typography>No item selected</Typography>
			)}

			{/* Snackbar for showing status update notification */}
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={3000}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				variant="filled"
				severity={snackbarSeverity}
				onClose={handleSnackbarClose}
				message={snackbarMessage}
			>
				 <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          
          {snackbarMessage}
        </Alert>
				</Snackbar>
		</Drawer>
	);
};

export default ItemDrawer;
