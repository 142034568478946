
import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Button,
  Divider,
  Box,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ItemDrawer from "../admin-ops-sp/admin-ops/ItemDrawer";
import DeleteIcon from "@mui/icons-material/Delete";
import PageFooter from "../footer/PageFooter";
import {
  getIsOps,
  getSessionSPOpsGrpID,
  getUserEmail,
} from "../utils/sessionCheck";
import {
  OrderList,
  LoadMoreOrderList,
  OrderItem,
  EditOrder,
  UpdateOrder,
} from "../DatabaseAPI/DatabaseQuery";
import {
  setUserEmail,
  setIsAdmin,
  setIsOps,
  setIsSPOps,
  setSPUserGrpID,
  setSessionSPOpsGrpID,
} from "../utils/sessionCheck";
import { getIsAdmin } from "../utils/sessionCheck";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SaveIcon from "@mui/icons-material/Save";
import NavBar2 from "../components/Navbar2";
import { reload } from "firebase/auth";
import { getLoginRoleGrp4AdminScreens } from "../DatabaseAPI/DatabaseQuery";
import SnackbarComponent from "../components/SnackbarComponent";


const OrderProcessing = () => {
  const [orders, setOrders] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  // const [age, setAge] = React.useState("");
  const [selectedStatus, setSelectedStatus] = useState({});
  const [isChanged, setIsChanged] = useState({});
  // const { cart, setCart, products, prodDetailID, setProdDetailID } =
  //   CartState();
  // Show the success or failuer message as snackbar
  const [open, setOpen] = React.useState(false);
  //let ordAlertMsg = "Your Order Number - ";
  const [ordAlertMsg, setOrdAlertMsg] = React.useState("");
  const [msgType, setMsgType] = React.useState("success");
  const [loadMore, setLoadMore] = useState(false);
  const [loadMoreOption, setLoadMoreOption] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
  // const [isAdmin2,setIsAdmin2] = useState(false);
  // const [isOps2,setIsOps2] = useState(false);
  const [isSp2,setIsSp2] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // could be "error", "warning", etc.

  const navigate = useNavigate();

  const handleDropdownChange = (prodDetailID, event) => {
    setSelectedStatus((prevStatus) => ({
      ...prevStatus,
      [prodDetailID]: event.target.value,
    }));

    setIsChanged((prevChanged) => ({
      ...prevChanged,
      [prodDetailID]: true,
    }));
  };
  const handleSPDropdownChange = (prodDetailID, event) => {
    setSelectedStatus((prevStatus) => ({
      ...prevStatus,
      [prodDetailID]: event.target.value,
    }));

    setIsChanged((prevChanged) => ({
      ...prevChanged,
      [prodDetailID]: true,
    }));
  };

  const handleOpenDrawer = (id) => {
    let cd = getIsAdmin();
    // console.log("cd", cd);
    if (cd) {
      try {
        // console.log("Selected ID: ", id);
        const item = orders.find(item => item.ordID === id);
        // console.log("item", item);
        setSelectedItem(item);
      } catch (error) {
        alert("You have encountered an error: " + error.message);
      }
    }
    else{
      alert("You are not authorized to edit the orders");
    }
  };
  

  const handleSave = async (prodDetailID) => {
    const confirmDelete = true
    if (confirmDelete) {
      try {
        const ListOrd = await EditOrder(prodDetailID);
        const newList = ListOrd.map((item) => ({
          ...item,
          ordOverallStatus: selectedStatus[prodDetailID],
        }));
        await UpdateOrder(ListOrd[0].id, newList[0]);
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.ordID === prodDetailID
              ? { ...order, ordOverallStatus: selectedStatus[prodDetailID] }
              : order
          )
        );
        // Clear the selected status for the saved order
        setSelectedStatus((prevStatus) => {
          const newStatus = { ...prevStatus };
          delete newStatus[prodDetailID];
          return newStatus;
        });
  
        setIsChanged((prevChanged) => {
          const newChanged = { ...prevChanged };
          delete newChanged[prodDetailID];
          return newChanged;
        });
  
        // Show success message in Snackbar
        setSnackbarMessage("Order Status Updated");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } catch (error) {
        // Show error message in Snackbar if something goes wrong
        setSnackbarMessage("Failed to update order status");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    }
  };
  

  const handleCloseDrawer = () => {
    setSelectedItem(null);
    window.location.reload();
  };


  const removeFromCart = async (prodDetailID) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this order?"
    );
    if (confirmDelete) {
      const ListOrd = await EditOrder(prodDetailID);
      const newList = ListOrd.map((item) => {
        return {
          ...item,
          ordIsDeleted: true,
        };
      });
      await UpdateOrder(ListOrd[0].id, newList[0]);
      setOrders((currentCart) => {
        // Filter out the item with the matching productId
        const updatedCart = currentCart.filter(
          (item) => item.ordID !== prodDetailID
        );
        setSnackbarMessage("Order Deleted ");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        return updatedCart;
      });

    }
  };


  const logUser4AdminScreens = async () => {
      const userEmail = await getUserEmail();
      try {
        // console.log("User email for Admin screens is:", userEmail);

        if (userEmail) {
          const loginGrpRole4AdminScreens = await getLoginRoleGrp4AdminScreens(userEmail,
            // console.log("User email for Admin screens is")
          );
          // console.log(
          //   "LoginGrpRole data received is >>> ",
          //   loginGrpRole4AdminScreens
          // );
          let recSPUserRole = "";
          let recSPUserGroupID = "";
          for (let i = 0; i < loginGrpRole4AdminScreens.length; i++) {
            // if(i=0)
            // {
            // console.log(
            //   "SPUser role retruned is >>> ",
            //   loginGrpRole4AdminScreens[i].spUserRole
            // );
            recSPUserRole = loginGrpRole4AdminScreens[i].spUserRole;
            recSPUserGroupID = loginGrpRole4AdminScreens[i].spUserGrpId;
            // }
          }

          // console.log("Inside signup if", recSPUserRole, recSPUserGroupID);

          switch (recSPUserRole) {
            case "SPOps":
              setIsSPOps(true);
              setSPUserGrpID(loginGrpRole4AdminScreens.SPUserGrpID);
              //setSessionSPOpsGrpID(loginGrpRole4AdminScreens.SPUserGrpID);
              setSessionSPOpsGrpID(recSPUserGroupID);
							setIsSp2(true);
              // console.log("User is SP Ops");
              // console.log("SP user Grp id is >>", recSPUserGroupID);
              CreateList();
              break;
              case "InterOps":
                setIsOps(true);
                setSPUserGrpID("INTEROPS-1001");
                setSessionSPOpsGrpID(recSPUserGroupID);
                // console.log("User is Ops");
                CreateList();
                break;
                case "InterAdmin":
                  setIsAdmin(true);
                  setSPUserGrpID("INTERADM-1002");
                  setSessionSPOpsGrpID(recSPUserGroupID);
                  CreateList();
                  // console.log("User is not Ops or Customer");
                  break;
            default:
              // console.log("User is not authorised to login here");
              navigate("/");
          }
        }
        else{
          // console.log("User email not found");
          navigate("/");
        }
      } catch (error) {
        // console.error("Error in finding the user: ", error);
        navigate("/");
      };
  };
  const CreateList = async () => {
    const productList = await OrderList(getSessionSPOpsGrpID());
    // console.log("Product list:", productList);
    // productList.sort((a, b) => a.ordOverallStatus - b.ordOverallStatus);
    setOrders(productList);
		// console.log("Product list:", productList);
    setLoadMore(true);
    setLoadMoreOption(true);
  };

  useEffect(() => {
   logUser4AdminScreens();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };


  const [searchValue, setSearchValue] = useState("");
  const [searchEmailValue, setSearchEmailValue] = useState("");

  const handleOrderChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handleEmailChange = (event) => {
    setSearchEmailValue(event.target.value);
  };


  const handleSearchClick = () => {
    setOrders(
      orders.filter((item) => {
        // console.log(item)
        // console.log(searchValue)
        return (
          item.ordID.includes(searchValue) &&
          item.ordEmailID.includes(searchEmailValue)
        );
      })
    );
    setLoadMoreOption(false);
  };

  const handleSearchCancel = () => {
    window.location.reload();
  };

  const GetOrder = async () => {
    if (orders.length === 0) {
      const productList = await OrderItem(searchValue, searchEmailValue);
      setOrders(productList);
      setLoadMoreOption(false);
    }
  };

  useEffect(() => {
    if (orders.length===0 && loadMore) {
      GetOrder();
    }
  }, [orders]);


  const LoadData = async () => {
    try {
      const newCards = await LoadMoreOrderList();
      // newCards.sort((a, b) => a.ordOverallStatus - b.ordOverallStatus);
      setOrders((prevCards) => [...prevCards, ...newCards]);

      if (newCards.length < 1) {
        setLoadMoreOption(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const statusOp = process.env.REACT_APP_STATUS.split(",");

  return (
    <>
      <NavBar2 cartDisplayProp={'none'}
              showMenuBarProp={'none'} 
      />
      
			<Box maxWidth="xl" height="auto" margin={2}>
        <Paper
      elevation={3}
      margin={2}
      style={{
        padding: "10px",
        margin: "auto",
        alignItem: "center",
        alignSelf: "center",
				border: "1px solid #07b307",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "10px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", width: "100%", }}>
        <TextField
          sx={{ flex: 1, marginRight: "20px",bgcolor: "#e8f5e9" }}
          id="orderSearch"
          label="Search Order ID"
          value={searchValue}
          onChange={handleOrderChange}
          variant="outlined"
        />
        <TextField
          sx={{ flex: 1, marginRight: "20px",bgcolor: "#e8f5e9" }}
          id="emailSearch"
          label="Search Email ID"
          value={searchEmailValue}
          onChange={handleEmailChange}
          variant="outlined"
        />
        <IconButton
          aria-label="search"
          size="medium"
          sx={{ marginRight: "10px", color: "#07b307" }}
          onClick={handleSearchClick}
        >
          <SearchIcon />
        </IconButton>
        <IconButton
          aria-label="cancel"
          size="medium"
          sx={{  color: "#ff4d4d" }}
          onClick={handleSearchCancel}
        >
          <CancelIcon />
        </IconButton>
      </Box>
    </Paper>
    </Box>
    
      <Snackbar
      anchorOrigin={{  vertical: 'top', horizontal: 'centre'  }}
  open={snackbarOpen}
  autoHideDuration={3000}
  onClose={() => setSnackbarOpen(false)}
  variant="filled"
>
  <Alert onClose={() => setSnackbarOpen(false)} anchorOrigin={{  vertical: 'bottom', horizontal: 'center'  }} variant="filled" severity={snackbarSeverity}>
    {snackbarMessage}
  </Alert>
</Snackbar>

      <Box maxWidth="xl" height="auto" margin={2}>
        <Paper
          elevation={3}
          style={{
            padding: "20px",
            marginTop: "20px",
            border: "1px solid #07b307",
            height: "auto",
          }}
        >

      <Table stickyHeader>
        <TableHead>
          <TableRow sx={{ backgroundColor: '#f5f5f5', borderBottom: '3px solid #07b307' }}>
            {['Order ID', 'Amount', 'Email', 'Phone Number', 'Delivery Address', 'Status', 'Action Item'].map((header) => (
              <TableCell key={header} sx={{ fontWeight: 'bold', borderBottom: '2px solid #07b307', color: '#07b307', textAlign: 'center',position: 'sticky', top: 0 }}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {orders?.map((order, index) => (
            <TableRow
              key={order.ordID}
              sx={{
                backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff',
                '&:hover': { backgroundColor: '#e0ffe0' },
              }}
            >
              <TableCell sx={{ textAlign: 'center',padding: "10px 15px" }}>{order.ordID}</TableCell>
              <TableCell sx={{ textAlign: 'center',padding: "10px 15px" }}>
                <meta charSet="UTF-8" />₹{order.ordTotalAmountPayable}
              </TableCell>
              {isSp2 && order.ordOverallStatus===1 ? (<TableCell sx={{ textAlign: 'center',padding: "10px 15px" }}>{" "}</TableCell>)
              :(<TableCell sx={{ textAlign: 'center',padding: "10px 15px" }}>{order.ordEmailID}</TableCell>)}
              {isSp2 && order.ordOverallStatus===1 ? (<TableCell sx={{ textAlign: 'center',padding: "10px 15px" }}>{" "}</TableCell>)
              :(<TableCell sx={{ textAlign: 'center',padding: "10px 15px" }}>{order.ordPhoneNumber}</TableCell>)}
              {isSp2 && order.ordOverallStatus===1 ? (<TableCell sx={{ textAlign: 'center',padding: "10px 15px" }}>{" "}</TableCell>)
              :(<TableCell sx={{ textAlign: 'center',padding: "10px 15px" }}>{order.ordCustomerDeliveryAddress}</TableCell>)}
              
              <TableCell sx={{ textAlign: 'center',padding: "10px 15px" }}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id={`statusLabel-${order.ordID}`}>Status</InputLabel>

                    {isSp2 ? (<>{order.ordOverallStatus===1 ? (<Select
                      labelId={`statusLabel-${order.ordID}`}
                      id={`status-${order.ordID}`}
                      value={order.ordOverallStatus}
                      label="Status"
											disabled={!(order.ordOverallStatus === 1)}
                      onChange={(event) => {
												handleDropdownChange(order.ordID, event)}}
                    >
                      {statusOp &&
                        statusOp.slice(0,2).map((item, index) => (
                          <MenuItem key={index + 1} value={index + 1}>
                            {item}
                          </MenuItem>
                        ))}
                    </Select>
										):(<Select
                      labelId={`statusLabel-${order.ordID}`}
                      id={`status-${order.ordID}`}
                      value={order.ordOverallStatus}
                      label="Status"
											disabled={!(order.ordOverallStatus === 1)}
                      onChange={(event) => {console.log("cutiepie: ",order.ordOverallStatus);
												handleDropdownChange(order.ordID, event)}}
                    >
                      {statusOp &&
                        statusOp.map((item, index) => (
                          <MenuItem key={index + 1} value={index + 1}>
                            {item}
                          </MenuItem>
                        ))}
                    </Select>)}</>
										) : (
										<Select
                      labelId={`statusLabel-${order.ordID}`}
                      id={`status-${order.ordID}`}
                      value={order.ordOverallStatus}
                      label="Status"
                      onChange={(event) => {handleDropdownChange(order.ordID, event)}}
                    >
                      {statusOp &&
                        statusOp.map((item, index) => (
                          <MenuItem key={index + 1} value={index + 1}>
                            {item}
                          </MenuItem>
                        ))}
                    </Select>)}
                  </FormControl>
                </Box>
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                <Tooltip title="Save" arrow>
                  <IconButton
                    aria-label="Save"
                    variant="contained"
                    color="primary"
                    // disabled={!isChanged[order.ordID]}
                    onClick={() => handleSave(order.ordID)}
                  >
                    <SaveIcon sx={{ color: 'green' }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="View / Edit" arrow>
                  <IconButton onClick={() => handleOpenDrawer(order.ordID)}>
                    <VisibilityIcon sx={{ color: 'green' }} />
                  </IconButton>
                </Tooltip>

                {getIsAdmin() && (
                  <Tooltip title="Delete" arrow>
                    <IconButton onClick={() => removeFromCart(order.ordID)}>
                      <DeleteIcon sx={{ color: 'red' }} />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    
          <ItemDrawer
        open={!!selectedItem}
        onClose={handleCloseDrawer}
        item={selectedItem}
      />
          {loadMoreOption && (
            <Box sx={{ m: 2, width: "100%" }}>
              <Button
                variant="contained"
                sx={{
                  px: "44%",
                }}
                onClick={LoadData}
              >
                <b>Load&nbsp;More... </b>
              </Button>
            </Box>
          )}
          <Divider style={{ margin: "20px" }} />
        </Paper>
</Box>


      <PageFooter />
    </>
  );
};

export default OrderProcessing;

