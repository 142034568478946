import * as React from "react";
import Box from "@mui/material/Box";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { AppBar, Button, SpeedDial, SpeedDialAction, SpeedDialIcon, Tooltip } from "@mui/material";
import { Menu } from "@mui/material";
import { MenuItem } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import CheckoutPage2 from "./checkoutpage2";
import { CartState } from "../context/ShopContext";
import { GridCardExpand } from "../DatabaseAPI/DatabaseQuery";
import {
  clearUserEmail,
  clearIsAdmin,
  clearIsOps,
  getUserEmail,
  clearAllSessionObjects,
} from "../utils/sessionCheck";
import IconButton from "@mui/material/IconButton";
import Logo1 from "../svgicons/ReUrjaLogo_v1.svg";
import HomeIcon from '@mui/icons-material/Home';
import { useEffect } from "react";

import {ReactComponent as WhatsAppReactLogo} from "../svgicons/whatsapp-svgrepo-com.svg"
import PhoneIcon from '@mui/icons-material/Phone';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';


// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';

// TODO remove, this demo shouldn't need to reset the theme.
// const defaultTheme = createTheme();

// export default function NavBar({cartDisplayProp='show'}) {
  export default function OldNavBar(props) {

  // by default Cart icon will be visible in Nav bar. However cartDisplayProp can be used to toggle the view
  // by assigning "none" or "show" values. Default value will be "show"
  // const [cartDisplay, setCartdisplay] = useState('show')
  // const [showMenuBarProp, setShowMenuBarProp] = useState('show')

  
  useEffect(() => {

    console.log('Admin flow - Propos received for Navbar are >>', props);
    // setCartdisplay(props.cartDisplay);
    // setShowMenuBarProp(props.showMenuBarProp);

    // console.log("values of cart and menu display are >>>", cartDisplay, showMenuBarProp);
  }, []);

  const [anchor, setAnchor] = useState(null);
  const [anchorBattery, setAnchorBattery] = useState(null);
  const [anchorEnquiry, setAnchorEnquiry] = useState(null);
  const navigate = useNavigate();

  const { cart, setProducts, setCart, updateProducts } = CartState();
  // Calculate total number of items in cart
  const totalItemsInCart = cart.reduce((total, item) => total + item.qty, 0);

  const handleMenuClick = (event) => {
    setAnchor(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchor(null);
  };

  const handleHomeClick = (event) => {
    navigate("/home");
  };

  const handleBatteryMenuClick = (event) => {
    setAnchorBattery(event.currentTarget);
  };
  const handleBatteryMenuClose = () => {
    setAnchorBattery(null);
  };

  const handleEnquiryMenuClick = (event) => {
    setAnchorEnquiry(event.currentTarget);
  };
  const handleEnquiryMenuClose = () => {
    setAnchorEnquiry(null);
  };

  const handleTrackOrderClick = (event) => {
    navigate("/orders");
  };

  // const handleBatteryClick = () => {
  //   navigate("/login");
  // };
  // const handleSolarClick = () => {
  //   navigate("/ongrid", { state: { formType: "On-Grid" } });
  // };

  const handleKnowledbaseClick = () => {
    navigate("/knowBs");
  };

  const handleBatteryClick = async (prodType) => {
    try {
      // const data = await FilteredCards("Battery", 8500, 18);
      // console.log(data);

      // const productList = await FilteredCards("Battery", 8500, 18); // This should log the array with key-value pairs
      const productList = await GridCardExpand(1, 25, prodType);
      updateProducts(productList);
      // console.log(ALLPRODUCTLIST);
      // setProducts(ALLPRODUCTLIST);
      // if (prodType === "Solar") {
      //   navigate("/login", { state: { category: "solar" } });
      // }
      navigate("/login");

      // Now you can use the 'data' array in your React component
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEnquiryClick = (type) => {
    navigate("/enq", { state: { formType: type } });
  };

  const handleLogout = () => {
    // clearUserEmail();
    // clearIsOps();
    // clearIsAdmin();
    clearAllSessionObjects();
    updateProducts([]);
    setCart([]);
    navigate("/");
  };

  return (
    // <AppBar position="fixed">
    // <AppBar position="fixed"  style={{ background: 'white', boxShadow: 'none'}}
    // >
    <Box>
      <Box
        height={30}
        // width={200}
        // my={4}
        mt={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={4}
        p={2}
        // sx={{ border: "2px solid grey" }}
      >
        <Link to={"/home"}>
          {/* <img */}
          <Box
            // component="section"
            component="img"
            alt="ReUrja.com"
            src={Logo1}
            sx={{
              // py: 2,
              // px: 8,
              // mx: 3,
              // bgcolor: "#25d911",
              // border: "1px solid green",
              // maxHeight: "80%",
              // maxWidth: "80%",
              // minHeight: "40%",
              // minWidth: "40%",
              height: "55%",
              width: "55%",
            }}
          ></Box>
          {/* </img> */}
        </Link>
        <Box component="section" display="flex" sx={{ p: 2 }}>
          {/* <LocationOnIcon sx={{ p: 0, m: 0 }} />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Your Location
          </Typography> */}
        </Box>

        {getUserEmail() ? (
          <Box component="section" display="flex" gap={2} sx={{ p: 2 }}>
            {" "}
            <Tooltip title="View Cart">
            {/* <IconButton aria-label="delete" size="large" sx={{display:cartDisplay}}> */}
            <IconButton aria-label="delete" size="large" sx={{display:props.cartDisplayProp}}>
              <Link to={"/checkout"}>
                <Badge badgeContent={totalItemsInCart} color="success">
                  <ShoppingCartIcon size="large"></ShoppingCartIcon>
                </Badge>
              </Link>
            </IconButton>
            </Tooltip>
            <Tooltip title="Logout">
            <IconButton aria-label="delete" size="large" onClick={handleLogout}>
              <ExitToAppIcon />
            </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <Box>
            <Link to="/login">LogIn</Link> / <Link to="/signup">SignUp</Link>
          </Box>
        )}
      </Box>
      {/* <Divider variant="middle" sx={{ p: 1, borderBottomWidth: 2, bgcolor:"green" }}></Divider> */}
      <Divider
        variant="middle"
        sx={{ bgcolor: "green", borderBottomWidth: "2px", mt: 2 }}
      ></Divider>
      <Box
        height={10}
        // width={200}
        // my={4}
        mt={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={4}
        // p={2}
        pt={1}

        // sx={{ border: "2px solid grey" }}
      >
        <Toolbar sx={{display: props.showMenuBarProp}}>
        {/* <Toolbar> */}

          {/* ######## START commented for first release in June 2024 ####### */}
          {/* <Button
            color="inherit"
            onClick={handleMenuClick}
            startIcon={<MenuIcon />}
          ></Button>

          {getUserEmail() ? (
            <Menu
              anchorEl={anchor}
              open={Boolean(anchor)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          ) : (
            console.log("User not logged in")
          )} */}

          {/* ######## END commented for first release in June 2024 ####### */}

          <Button
                      color="inherit"
                      variant="text"
                      onClick={handleHomeClick}
                      sx={{ color: "#f79646", fontWeight: "bold" }}
                      startIcon={<HomeIcon/>}
                      
            >
              Home
            </Button>
            <Typography sx={{color:'green'}}>|</Typography>

          {/* ####### START - For Release 1 ONLY - Temporarily hidden as all optionas are not
           available at this moment */}

          {/* <Button
            color="inherit"
            variant="text"
            onClick={handleBatteryMenuClick}
            sx={{ color: "#127008", fontWeight: "bold" }}
          >
            Battery
          </Button>
          <Menu
            anchorEl={anchorBattery}
            open={Boolean(anchorBattery)}
            onClose={handleBatteryMenuClose}
          >
            <MenuItem onClick={() => handleBatteryClick("Lead")}>
              Lead Battery
            </MenuItem>
            <MenuItem 
              disabled  // disabled for Jun 2024 release
              onClick={() => handleBatteryClick("Battery")}>
              Lithium Battery
            </MenuItem>
            
            
          </Menu> */}
          {/* <Button
            variant="text"
            sx={{ color: "#127008", fontWeight: "bold" }}
            onClick={() => handleBatteryClick("Battery")}
          >
            Battery
          </Button> */}
          {/* <Button
            variant="text"
            sx={{ color: "#127008", fontWeight: "bold" }}
            onClick={() => handleBatteryClick("Solar")}
          >
            Solar
          </Button> */}

          
          {/* <Button
            variant="text"
            sx={{ color: "#127008", fontWeight: "bold" }}
            onClick={() => handleBatteryClick("EV")}
            
          >
            EV
          </Button> */}

          

          {/* <Button
            variant="text"
            sx={{ color: "#127008", fontWeight: "bold", display: "none" }}
          >
            Wind
          </Button>
          <Button
            variant="text"
            sx={{ color: "#127008", fontWeight: "bold", display: "none" }}
          >
            Accessories
          </Button> */}

          {/* ####### END - For Release 1 ONLY - Temporarily hidden as EV is not available at this moment */}

          
          <Button
            color="inherit"
            variant="text"
            onClick={handleEnquiryMenuClick}
            sx={{ color: "#127008", fontWeight: "bold" }}
          >
            Enquiry Forms <Typography variant="caption" sx={{color:"orange"}}> &nbsp;v</Typography>
          </Button>
          
          <Typography sx={{color:'green'}}>|</Typography>

          <Menu
            anchorEl={anchorEnquiry}
            open={Boolean(anchorEnquiry)}
            onClose={handleEnquiryMenuClose}
          >
            <MenuItem onClick={() => handleEnquiryClick("OnGrid Solar")}>
              OnGrid Solar
            </MenuItem>
            <MenuItem onClick={() => handleEnquiryClick("OffGrid Solar")}>
              OffGrid Solar
            </MenuItem>
            {/* <MenuItem onClick={() => handleEnquiryClick("OnGrid MicroWind")}>
              OnGrid MicroWind
            </MenuItem>
            <MenuItem onClick={() => handleEnquiryClick("OffGrid MicroWind")}>
              OffGrid MicroWind
            </MenuItem> */}
            <MenuItem onClick={() => handleEnquiryClick("Solar AMC")}>
              Solar AMC
            </MenuItem>
            <MenuItem onClick={() => handleEnquiryClick("Battery AMC")}>
              Battery AMC
            </MenuItem>
            {/* <MenuItem onClick={() => handleEnquiryClick("AMC MicroWind")}>
              AMC MicroWind
            </MenuItem> */}
            {/* <MenuItem
              onClick={() => handleEnquiryClick("Rainwater Harvesting")}
            >
              Rainwater Harvesting
            </MenuItem>
            <MenuItem onClick={() => handleEnquiryClick("Custom Battery")}>
              Custom Battery
            </MenuItem> */}
            {/* <MenuItem onClick={() => handleEnquiryClick("Franchisee")}>
              Franchisee
            </MenuItem>
            <MenuItem onClick={() => handleEnquiryClick("Partnership")}>
              Partnership
            </MenuItem>
            <MenuItem
              onClick={() => handleEnquiryClick("New Business Proposal")}
            >
              New Business Proposal
            </MenuItem> */}
            <MenuItem onClick={() => handleEnquiryClick("Contact Us")}>
              Contact Us
            </MenuItem>
            {/* <MenuItem onClick={() => handleEnquiryClick("Site Survey")}>
              Site Survey
            </MenuItem> */}
            {/* <MenuItem
              onClick={() => handleEnquiryClick("Energy Constervation")}
            >
              Energy Constervation
            </MenuItem>
            <MenuItem onClick={() => handleEnquiryClick("Water Conservation")}>
              Water Conservation
            </MenuItem>
            <MenuItem onClick={() => handleEnquiryClick("Waste Management")}>
              Waste Management
            </MenuItem>
            <MenuItem onClick={() => handleEnquiryClick("Recycling")}>
              Recycling
            </MenuItem> */}
            {/* <MenuItem onClick={() => handleEnquiryClick("Others")}>
              Others
            </MenuItem> */}
            {/* <MenuItem onClick={handleLogout}>Logout</MenuItem> */}
          </Menu>
          <Button
            color="inherit"
            variant="text"
            onClick={handleTrackOrderClick}
            sx={{ color: "#127008", fontWeight: "bold" }}
          >
            Track Orders
          </Button>
          
          <Typography sx={{color:'green'}}>|</Typography>

          <Button
            variant="text"
            sx={{ color: "#127008", fontWeight: "bold" }}
            onClick={handleKnowledbaseClick}
          >
            Knowledgebase
          </Button>
          
          <Typography sx={{color:'green'}}>|</Typography>
      
     

      
          
        </Toolbar>
      </Box>
      {/* <Divider variant="middle" sx={{ p:1, borderBottomWidth:5, mb:1 }}></Divider> */}
      <Divider
        variant="middle"
        sx={{ bgcolor: "green", borderBottomWidth: "2px", mt: 2, mb: 1 }}
      ></Divider>
      {/* <Box
        height={30}
        maxWidth={100}
        sx={{
          bgcolor: "primary.main",
          border: "1px solid green",
        }}
      ></Box> */}
    </Box>
    // </AppBar>
    
  );
}
