import * as React from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { AppBar, Box, Fab, IconButton, SpeedDial, SpeedDialAction, SpeedDialIcon, Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
    deepOrange,
    deepPurple,
    green,
    lightGreen,
} from "@mui/material/colors";
import { Grid, Button } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import upsbattery from "../svgicons/upsbattery.svg";
import autorickshaw from "../svgicons/autorickshaw.svg";
import car4W from "../svgicons/car4W.svg";
import motorcycle2W from "../svgicons/motorcycle2W.svg";
import truckCV from "../svgicons/truckCV.svg";
import inverterbattery from "../svgicons/inverterbattery.svg";
import lithiumBatteryIcon from "../svgicons/LithiumBatteryIcon.svg";
import {
    FilteredCards,
    GridCards,
    GridCardExpand,
} from "../DatabaseAPI/DatabaseQuery";
import { useEffect } from "react";
// import { ALLPRODUCTLIST } from "../pages/ProdCardListPg";
import { useNavigate, Link } from "react-router-dom";
import { CartState } from "../context/ShopContext";


import panels from "../svgicons/panels.svg";

// home page row number Two
import ongrid from "../svgicons/ongrid.svg";
import solaropenaccess from "../svgicons/GreenOpenAccess.svg"
import offgrid from "../svgicons/offgrid.svg";
import solarprojectmanagement from "../svgicons/ProjectManagement.svg";

import solarAMC from "../svgicons/AMC-Solar-PNG.svg";
import dgsetAMC from "../svgicons/AMC-DGSet-PNG.svg";
import cctvAMC from "../svgicons/AMC-CCTV-PNG.svg"
import batteryAMC from "../svgicons/AMC-Battery-PNG.svg";

// home page row number Three
import heatpump from "../svgicons/HeatPump-PNG.svg";
import dgpvsync from "../svgicons/DGPV- PNG.svg";
import led from "../svgicons/LED - SVG.svg";
import automation from "../svgicons/automation.svg";
import co2 from "../svgicons/CarbonCredits.svg";


import energyaudit from "../svgicons/EnergyAuditor.svg";
import greenenergyconsulting from "../svgicons/GreenEnergyConsultant.svg";
import trainings from "../svgicons/Trainings.svg";



// import heatpump1 from "../svgicons/heatpump1.jpg";
import PhoneIcon from '@mui/icons-material/Phone';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import { ReactComponent as WhatsAppReactLogo } from "../svgicons/whatsapp-svgrepo-com.svg"
import { getUserEmail, setSessionProdListByCategory, setSessionProdListByType } from "../utils/sessionCheck";
import { useState } from "react";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
}));

const paperstyle = {
    m: 1,
    padding: 10,
    textAlign: "center",
    //height: "35vh",
    height: "auto", // updated to auto to fit in all components in Paper
    width: 300,
    margin: "20px auto",
    border: "2px solid #07b307",
};
const typestylehead = { fontWeight: "bold" };

const typestylebody = {
    color: green[600],
    margin: 1,
    fontWeight: 600,
    textTransform: "none",
};

// export let ALLPRODUCTLIST = [];

export default function NewHomePgComp2() {
    const navigate = useNavigate();
    //   const { updateProducts } = CartState();

    //   const handleProdCategoryClick = async (prodType) => {
    const handleProdCategoryClick = (prodCat) => {
        try {

            // set the product listing Type for a actual qury on filter page after authentication
            setSessionProdListByCategory(prodCat);
            navigate("/filters");
            // Now you can use the 'data' array in your React component
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    //   const handleClick = async (type) => {
    const [s, setS] = useState('')
    // const handleClick = (type) => {
    //     try {
    //         setSessionProdListByType(type);
    //         setS(type);
    //         navigate("/filters",{state:{s:type}});
    //         // Now you can use the 'data' array in your React component
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //     }
    // };

    const handleClick = (type) => {
        try {
            // Update session product list and state
            setSessionProdListByType(type);
            setS(type); // Update `s` before navigating

            // Use the updated state for navigation
            navigate("/filters", { state: { s: type } }); // Pass the `type` directly if it's needed on the filters page
        } catch (error) {
            console.error("Error handling click:", error);
        }
    };

    // const handleSolarClick = (type) => {


    //     if (!getUserEmail()) {
    // 		// User email is not present, navigate to the login page
    // 		navigate("/login");
    // 	} else {
    // 		// User email is present, navigate to the enquiry page with the type
    //         if (type === "onGrid") {
    //             navigate("/enq", { state: { formType: "On-Grid" } });
    //             // <Link to="ongrid" state={{ some: "On-Grid" }} />;
    //         } else {
    //             navigate("/enq", { state: { formType: "Off-Grid" } });
    //             // <Link to="ongrid" state={{ some: "Off-Grid" }} />;
    //         }
    // 	}
    // };


    //updated check function for click handling for enquiry form
    const handleEnqFormClick = (type) => {
        //   const state = { formTypeer: type === "onGrid" ? "On-Grid" : "Off-Grid" };
        navigate("/enq", { state: { formType: type } });
        //   <Link to={{ pathname: "/ongrid", state }}></Link>;
    };


    return (
        <div>
            {/* First row on the page */}
            <Stack
                // spacing={2} direction="row"
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
            >
                {/* Lead acid Batteries */}
                <Paper elevation={10} style={paperstyle}>
                    <Typography style={typestylehead} variant="h6">
                        Lead Acid Battery
                    </Typography>
                    <Grid2 container margin={2} spacing={2}>
                        <Grid2 item p={2} xs={12} sm={4}>
                            <Button onClick={() => handleClick("InverterBattery")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={inverterbattery}
                                        alt="inverterbattery"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>Inverter</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={2} xs={12} sm={4}>
                            <Button onClick={() => handleClick("InverterBattery")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={upsbattery}
                                        alt="upsbattery"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>UPS</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={2} xs={12} sm={4}>
                            <Button onClick={() => handleClick("2W")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={motorcycle2W}
                                        alt="motorcycle2W"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>2W</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={2} xs={12} sm={4}>
                            <Button onClick={() => handleClick("CAR-SUV")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={car4W}
                                        alt="car4W"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>CAR</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={2} xs={12} sm={4}>
                            <Button onClick={() => handleClick("BusOrTruck")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={truckCV}
                                        alt="truckCV"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>CV</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={2} xs={12} sm={4}>
                            {/* <Button onClick={() => handleClick("Auto")}> */}
                            <Button onClick={() => handleClick("3W-LCV")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={autorickshaw}
                                        alt="autorickshaw"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>3W</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                    </Grid2>
                    <Button
                        onClick={() => handleProdCategoryClick("BatteryLeadAcid")}
                        sx={{}}
                        variant="text"
                    >
                        <b>Expand All</b>
                    </Button>
                </Paper>

                {/* Lithium Batteries */}
                <Paper elevation={10} style={paperstyle} >
                    <Typography style={typestylehead} variant="h6">
                        Lithium Battery
                    </Typography>
                    <Grid2 container margin={2} spacing={2} >
                        <Grid2 item p={2} xs={12} sm={4} >
                            <Button onClick={() => handleClick("Li12V")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={lithiumBatteryIcon}
                                        alt="Li 12 Battery"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>12V</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={2} xs={12} sm={4}>
                            <Button onClick={() => handleClick("Li24V")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={lithiumBatteryIcon}
                                        alt="Li 24V Battery"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>24V</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={2} xs={12} sm={4}>
                            <Button onClick={() => handleClick("Li36V")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={lithiumBatteryIcon}
                                        alt="Li 36V Battery"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>36V</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={2} xs={12} sm={4}>
                            <Button onClick={() => handleClick("Li48V")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={lithiumBatteryIcon}
                                        alt="Li 48V Battery"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>48V</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={2} xs={12} sm={4}>
                            <Button onClick={() => handleClick("Li72V")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={lithiumBatteryIcon}
                                        alt="Li 72V Battery"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>72V</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={2} xs={12} sm={4}>
                            <Button onClick={() => handleClick("Li96V")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={lithiumBatteryIcon}
                                        alt="Li 96V Battery"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>96V</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                    </Grid2>
                    <Button
                        onClick={() => handleProdCategoryClick("BatteryLithium")}
                        sx={{}}
                        variant="text"
                    >
                        <b>Expand All</b>
                    </Button>
                </Paper>
            </Stack>

            {/* Second row on the page covering Solar and AMC */}
            <Stack
                // spacing={2} direction="row"
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
            >
                {/* SolarEnergy */}
                <Paper elevation={10} style={paperstyle}>
                    <Typography style={typestylehead} variant="h6">
                        Solar Energy
                    </Typography>
                    <Grid2 container margin={2} spacing={2}>
                        <Grid2 item p={2} xs={12} sm={6}>
                            <Button onClick={() => handleEnqFormClick("On Grid Solar")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={ongrid}
                                        alt="ongrid"
                                        style={{ width: "100%", marginBottom: 5 }}
                                    />
                                    <Typography style={typestylebody}>
                                        On-Grid Solar
                                    </Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={2} xs={12} sm={6}>
                            <Button onClick={() => handleEnqFormClick("Green Open Access")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        width={35}
                                        src={solaropenaccess}
                                        alt="Solar Open Access"
                                        style={{ width: "100%", marginBottom: 5 }}
                                    />
                                    <Typography style={typestylebody}>
                                        Open Access
                                    </Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={2} xs={12} sm={6}>
                            <Button onClick={() => handleEnqFormClick("Off Grid Solar")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={offgrid}
                                        alt="Off Grid Solar"
                                        style={{ width: "100%", marginBottom: 5 }}
                                    />
                                    <Typography style={typestylebody}>Off-Grid Solar</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={2} xs={12} sm={6}>
                            <Button onClick={() => handleEnqFormClick("Solar Plant Project Management")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={solarprojectmanagement}
                                        alt="Solar Project Management"
                                        style={{ width: "100%", marginBottom: 5 }}
                                    />
                                    <Typography style={typestylebody}>Project Management</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                    </Grid2>
                    {/* <Button
                        sx={{}}
                        variant="text"
                        onClick={() => handleProdCategoryClick("Solar")}
                    >
                        <b>Expand All</b>
                    </Button> */}
                </Paper>

                {/* Electric Vehicles */}
                {/* <Paper elevation={10} style={paperstyle}>
                    <Typography style={typestylehead} variant="h6">
                        Electric vehicles
                    </Typography>
                    <Grid2 container margin={2} spacing={2}>
                        <Grid2 item p={2} xs={12} sm={6}>
                            <Button onClick={() => handleClick("Bicycle")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={electricbike}
                                        alt="electricbike"
                                        style={{ width: "100%", marginBottom: 5 }}
                                    />
                                    <Typography style={typestylebody}>EV Bicycle</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={2} xs={12} sm={4}>
                            <Button onClick={() => handleClick("Scooter")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={electricscooter}
                                        alt="electricscooter"
                                        style={{ width: "100%", marginBottom: 5 }}
                                    />
                                    <Typography style={typestylebody}>EV Scooter</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={2} xs={12} sm={4}>
                            <Button onClick={() => handleClick("Electric")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={evbike}
                                        alt="evbike"
                                        style={{ width: "100%", marginBottom: 5 }}
                                    />
                                    <Typography style={typestylebody}>EV Motorcycle</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                    </Grid2>

                    <Button
                        sx={{}}
                        variant="text"
                        onClick={() => handleProdCategoryClick("EV")}
                    >
                        <b>Expand All</b>
                    </Button>
                </Paper> */}

                {/* AMCs */}
                <Paper elevation={10} style={paperstyle}>
                    <Typography style={typestylehead} variant="h6">
                        Annual Maintenance (AMC)
                    </Typography>
                    <Grid2 container margin={2} spacing={2}>
                        <Grid2 item p={2} xs={12} sm={6}>
                            <Button onClick={() => handleEnqFormClick("AMC for Battery")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        width={50}
                                        src={batteryAMC}
                                        alt="Battery AMC"
                                        style={{ width: "100%", marginBottom: 5 }}
                                    />
                                    <Typography style={typestylebody}>Battery AMC</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={0} xs={12} sm={4} mt={2}>
                            <Button onClick={() => handleEnqFormClick("AMC for Solar Plant")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={solarAMC}
                                        alt="Solar plant AMC"
                                        style={{ width: "100%", marginBottom: 5 }}
                                    />
                                    <Typography style={typestylebody}>Solar AMC</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={0} xs={12} sm={4} ml={3}>
                            <Button onClick={() => handleEnqFormClick("AMC for Diesel Generator Set")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={dgsetAMC}
                                        alt="Diesel Generator Set AMC"
                                        style={{ width: "100%", marginBottom: 5 }}
                                    />
                                    <Typography style={typestylebody}>DG Set AMC</Typography>
                                </Stack>
                            </Button>
                        </Grid2>


                        <Grid2 item p={0} xs={12} sm={4} ml={3}>
                            <Button onClick={() => handleEnqFormClick("AMC for CCTV System")}
                                disabled>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={cctvAMC}
                                        alt="CCTV AMC"
                                        style={{ width: "100%", marginBottom: 5 }}
                                    />
                                    <Typography style={typestylebody}>CCTV AMC</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                    </Grid2>
                </Paper>
            </Stack>

            {/* Third row on the page covering Specialised produts and srvices */}
            <Stack
                // spacing={2} direction="row"
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
            >
                {/* Energy Conservation */}
                <Paper elevation={10} style={paperstyle}>
                    <Typography style={typestylehead} variant="h6">
                        Energy Conservation Products
                    </Typography>
                    <Grid2 container margin={2} spacing={2}>
                        <Grid2 item p={2} xs={12} sm={4}>
                            <Button onClick={() => handleClick("heatpump")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={heatpump}
                                        alt="Heat Pump"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>Heat Pump</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={2} xs={12} sm={4}>
                            <Button onClick={() => handleClick("dgpvsync")}
                                disabled>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={dgpvsync}
                                        alt="DG PV Synchoniztion"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>DGPV Sync</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={2} xs={12} sm={4}>
                            <Button onClick={() => handleEnqFormClick("Bulk LED Requirements")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={led}
                                        alt="LED"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>LED</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={0} xs={12} sm={4}>
                            <Button onClick={() => handleEnqFormClick("Automation Requirements")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={automation}
                                        alt="Automation"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>Automation</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={0} xs={12} sm={4} ml={3}>
                            {/* <Box sx={{color:lightGreen, border:1, width:100, fill:'lightblue'}}> */}
                            <Button onClick={() => handleEnqFormClick("Carbon Credit Requirements")} disabled >
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={co2}
                                        alt="Carbon Credits"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>Carbon Credits</Typography>
                                </Stack>
                            </Button>
                            {/* </Box> */}
                        </Grid2>

                    </Grid2>

                </Paper>

                {/* Lithium Batteries */}
                <Paper elevation={10} style={paperstyle} >
                    <Typography style={typestylehead} variant="h6">
                        Consultancy Services
                    </Typography>
                    <Grid2 container margin={2} spacing={2} >
                        <Grid2 item p={2} xs={12} sm={4} >
                            <Button onClick={() => handleEnqFormClick("Energy Audit")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={energyaudit}
                                        alt="Energy Audit"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>Energy Audit</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={2} xs={12} sm={4} ml={3}>
                            {/* <Box sx={{color:lightGreen, border:0, width:100, fill:'lightblue'}}> */}
                            <Button onClick={() => handleEnqFormClick("Green Energy Consultancy")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        width={35}
                                        src={greenenergyconsulting}
                                        alt="GreenEnergy Consultancy"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>Green Energy</Typography>
                                </Stack>
                            </Button>
                            {/* </Box> */}
                        </Grid2>
                        <Grid2 item p={2} xs={12} sm={4}>
                            <Button onClick={() => handleEnqFormClick("Training Requirements")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={trainings}
                                        alt="Trainings"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>Trainings</Typography>
                                </Stack>
                            </Button>
                        </Grid2>

                        {/* <Grid2 item p={2} xs={12} sm={4}>
                            <Button onClick={() => handleClick("CAR-SUV")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={car4W}
                                        alt="car4W"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>CAR</Typography>
                                </Stack>
                            </Button>
                        </Grid2>
                        <Grid2 item p={2} xs={12} sm={4}>
                            <Button onClick={() => handleClick("LCV-HCV")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={truckCV}
                                        alt="truckCV"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>CV</Typography>
                                </Stack>
                            </Button>
                        </Grid2>

                        <Grid2 item p={2} xs={12} sm={4}>
                            <Button onClick={() => handleClick("3W-LCV")}>
                                <Stack direction={"column"}>
                                    <img
                                        height={35}
                                        src={autorickshaw}
                                        alt="autorickshaw"
                                        style={{ width: "100%" }}
                                    />
                                    <Typography style={typestylebody}>3W</Typography>
                                </Stack>
                            </Button>
                        </Grid2>*/}
                    </Grid2>
                    {/* <Button
                        onClick={() => handleProdCategoryClick("BatteryLithium")}
                        sx={{}}
                        variant="text"
                    >
                        <b>Expand All</b>
                    </Button> */}

                </Paper>
            </Stack>



            {/* <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, bgcolor:'transparent' }}> */}
            <Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1 }}>

                <SpeedDial
                    ariaLabel="Contact us"
                    sx={{
                        position: 'fixed',
                        right: 16
                    }}
                    FabProps={{ color: "success", variant: "extended" }}
                    icon={
                        <SpeedDialIcon openIcon={

                            <Box sx={{ display: "flex", color: 'primary' }}>
                                <PermPhoneMsgIcon />
                                <Typography> Contact Us </Typography>
                            </Box>

                        }
                            icon={
                                <Box sx={{ display: "flex" }}>
                                    <PermPhoneMsgIcon />
                                    <Typography> Contact Us </Typography>
                                </Box>
                            }
                        />
                    }
                >

                    {/* // } PermPhoneMsgIcon /> <Typography>Contact Us</Typography>>} */}



                    <SpeedDialAction
                        key={'Call Us'}
                        icon={<PhoneIcon color="error" />}
                        tooltipTitle={"Call Us"}
                        sx={{ bgcolor: '#ffeb3b' }}
                        onClick={() => {
                            // alert("clicked on phone")

                            window.open("tel:9422652285", '_blank');
                        }}
                    />

                    <SpeedDialAction
                        key={'Chat with us'}
                        // icon= {<Link to={{ pathname: "https://wa.me/9422652285" }} target="_blank" ><WhatsAppReactLogo/> </Link>}
                        icon={<WhatsAppReactLogo />}
                        tooltipTitle={"Chat with us"}
                        sx={{ bgcolor: '#76ff03' }}
                        onClick={() => {
                            // window.location.href = "https://wa.me/9422652285"
                            window.open("https://wa.me/9422652285", '_blank');
                        }}

                    />
                </SpeedDial>

            </Box>
            {/* </AppBar> */}

        </div>
    );
}
