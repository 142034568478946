// import logo from './logo.svg';
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Filters from "./pages/Filters";
import EnqForm from "./pages/EnqForm";
import ProdCardListPg from "./pages/ProdCardListPg";
import CheckoutPage2 from "./pages/checkoutpage2";
import ProductPage from "./pages/productpage";
// import PaymentPage from "./pages/PaymentPage";
import LandingPage from "./pages/LandingPage";
import KnowledgeBasePg from "./pages/KnowledgeBasePg";
import AboutUsPg from "./footer/AboutUsPg";
import TnCPg from "./footer/TnCPg";
import ContactUsPg from "./footer/ContactUsPg";
import OrderProcessing from "./pages/OrderProcessing";
import EVProductDetails from "./pages/EVProductDetails";
import EVEnquiryForm from "./pages/EVEnquiryForm";
// import PhoneSignIn from "./pages/OTPAuth";
import ThankYouPage from "./pages/Thankyoupage";
import OrderProcessingCustomer from "./pages/OrderProcessingCustomer";
import NewLogIn from "./pages/NewLogin";
import OpsOrderList from "./admin-ops-sp/admin-ops/OpsOrderList";
// import OpsLogin2 from "./pages/OpsLogin2";
// import ExcelUpload2 from "./pages/ExcelUpload2";
import SPorderList from "./admin-ops-sp/sp/SPorderList";
import CustomerSignUp from "./pages/SignUpPage";
import OpsLogin from "./pages/OpsLogin";
import ExcelUpload from "./pages/ExcelUpload";
import EnquiryReview from "./pages/EnquiryReview";
import AssignSP from "./pages/AssignSP";
import PrivyPolicy from "./footer/PrivyPolicy";
import ThirdPrtyPolicy from "./footer/ThirdPrtyPolicy";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/home" element={<Home />} />
          <Route path="/filters" element={<Filters />} />
          <Route path="/enq" element={<EnqForm />} />
          {/* <Route path="/offgrid" element={<OffGrid />} /> */}
          <Route path="/login" element={<NewLogIn />} />
          {/* <Route path="/signin" element={<SignUp/>} /> */}
          {/* <Route path="/opadminorderlist" element={<OpsOrderList/>} /> */}
          {/* <Route path="/ServiceProvider" element={<SPorderList/>} /> */}
          {/* <Route path="/formsLogin" element={<FormsLoginPg />} /> */}
          <Route path="/9f5cb" element={<OpsLogin />} />
          {/* <Route path="/opslogin" element={<OpsLogin2 />} /> */}

          <Route path="/excel" element={<ExcelUpload />} />
          {/* <Route path="/excel2" element={<ExcelUpload2 />} /> */}
          <Route path="/prodlist" element={<ProdCardListPg />} />
          <Route path="/prodDtls" element={<ProductPage />} />
          <Route path="/checkout" element={<CheckoutPage2 />} />
          <Route path="/6g2hb" element={<OrderProcessing />} />
          <Route path="/4g3hb" element={<EnquiryReview />} />
          <Route path="/3g3hb" element={<AssignSP />} />


          <Route path="/orders" element={<OrderProcessingCustomer />} />
          {/* <Route path="/payment" element={<PaymentPage />} /> */}
          <Route path="/custsignup" element={<CustomerSignUp />} />
          <Route path="/product/:prodDetailID" component={<ProductPage />} />

          <Route path="/knowBs" element={<KnowledgeBasePg />} />
          
          <Route path="/aboutus" element={<AboutUsPg />} />
          <Route path="/contactus" element={<ContactUsPg />} />

          <Route path="/tnc" element={<TnCPg />} />
          <Route path="/privypolicy" element={<PrivyPolicy />} />
          <Route path="/3rdprtypolicy" element={<ThirdPrtyPolicy />} />
          
          
          {/* <Route path="/thx" element={<ThankYouPg />} /> */}
          <Route path="/EV" element={<EVProductDetails />} />
          <Route path="/testride" element={<EVEnquiryForm />} />
          {/* <Route path="otp" element={<PhoneSignIn />} /> */}
          <Route path="/thanku" element={<ThankYouPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
