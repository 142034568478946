import {
	Grid,
	Paper,
	Avatar,
	// TextField,
	Button,
	Typography,
	// Box,
	Stack,
	Snackbar,
	Alert,
} from "@mui/material";
import * as React from "react";
// import { green } from "@mui/material/colors";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";
import {
	setUserEmail,
	setIsAdmin,
	setIsOps,
	getIsOps,
	getIsAdmin,
	setIsSPOps,
	setSPUserGrpID,
	setSessionSPOpsGrpID,
} from "../utils/sessionCheck";
import { auth, provider, firestore } from "../firebase.config";
//   import { collection, query, where, getDocs } from "firebase/firestore";
import { signInWithPopup } from "firebase/auth";
import Divider from "@mui/material/Divider";
import { useEffect } from "react";
import { getLoginRoleGrp4AdminScreens } from "../DatabaseAPI/DatabaseQuery";
import NavBar2 from "../components/Navbar2";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";

const paperstyle = {
	padding: 20,
	height: "40vh",
	width: 460,
	margin: "20px auto",
};

const OpsLogin = () => {
	const [human, setHuman] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false	);
	const [snackbarMessage, setSnackbarMessage] = useState(" ");
	const [snackbarSeverity, setSnackbarSeverity] = useState("warning");

	const navigate = useNavigate();
	const sitekey1 = process.env.REACT_APP_RECAPCTHA_SITE_KEY;

	useEffect(() => {
		if (getIsOps("IsOps") || getIsAdmin("IsAdmin")) {
			navigate("/excel");
		}
	}, []);

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};  

	const logUser4AdminScreens = async () => {
		signInWithPopup(auth, provider).then(async (data) => {
			setUserEmail(data.user.email);
			try {
				// console.log("User email for Admin screens is:", data.user.email);

				if (data.user.email) {
					const loginGrpRole4AdminScreens = await getLoginRoleGrp4AdminScreens(
						data.user.email
						// console.log("User email for Admin screens is")
					);
					let recSPUserRole = "";
					let recSPUserGroupID = "";
					for (let i = 0; i < loginGrpRole4AdminScreens.length; i++) {
						
						recSPUserRole = loginGrpRole4AdminScreens[i].spUserRole;
						recSPUserGroupID = loginGrpRole4AdminScreens[i].spUserGrpId;
						// }
					}

					// console.log("Inside signup if", recSPUserRole, recSPUserGroupID);

					switch (recSPUserRole) {
						case "SPOps":
							setIsSPOps(true);
							setSPUserGrpID(loginGrpRole4AdminScreens.SPUserGrpID);
							//setSessionSPOpsGrpID(loginGrpRole4AdminScreens.SPUserGrpID);
							setSessionSPOpsGrpID(recSPUserGroupID);
							// console.log("User is SP Ops");
							// console.log("SP user Grp id is >>", recSPUserGroupID);
							navigate("/6g2hb");
							break;
						case "InterOps":
							setIsOps(true);
							setSPUserGrpID("INTEROPS-1001");
							setSessionSPOpsGrpID(recSPUserGroupID);
							// console.log("User is Ops");
							navigate("/excel");
							break;
						case "InterAdmin":
							setIsAdmin(true);
							setSPUserGrpID("INTERADM-1002");
							setSessionSPOpsGrpID(recSPUserGroupID);
							// console.log("User is not Ops or Customer");
							navigate("/excel");
							break;
						default:
							// console.log("User is not authorised to login here");
							navigate("/");
					}
				}
			} catch (error) {
				console.error("Error in finding the user: ", error);
				navigate("/");
			}
		});
	};

	function onChange(value) {
		if (value !== "NaN") {
			setHuman("True");
		}
	}

	return (
		<>
			<NavBar2 cartDisplayProp={"none"} showMenuBarProp={"none"} />
			<Divider variant="middle" sx={{ p: 1, borderBottomWidth: 5 }}></Divider>

			<Snackbar
					open={snackbarOpen}
					autoHideDuration={3000}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					variant="filled"
					severity={snackbarSeverity}
					onClose={handleSnackbarClose}
					message={snackbarMessage}
				>
					<Alert
						onClose={handleSnackbarClose}
						severity={snackbarSeverity}
						variant="filled"
						sx={{ width: "100%" }}
					>
						{snackbarMessage}
					</Alert>
				</Snackbar>
			<Grid
				container
				justifyContent="center"
				alignContent={"center"}
				sx={{ height: "100vh" }}
			>
				<Paper
					elevation={10}
					style={paperstyle}
					sx={{ padding: 3, bgcolor: "#ff9800" }}
				>
					<Stack direction="column" spacing={2} alignItems="center">
						<Avatar sx={{ bgcolor: "green" }}>
							<LockIcon />
						</Avatar>

						<ReCAPTCHA sitekey={sitekey1} onChange={onChange} />
						<Typography variant="h4">Sign in</Typography>
						<Button
							type="button"
							sx={{ bgcolor: "green", width: "60%", mt: 4 }}
							variant="contained"
							fullWidth
							onClick={() => {
								try {
									if (human === "True") {
										logUser4AdminScreens();
									} 
                  else {
                    console.log("You are ")
										setSnackbarMessage("Please verify you are not a robot");
										setSnackbarOpen(true);
                    // setSnackbarSeverity("warning");
									}
								} catch (error) {
									console.log('User did not log in', error);
								}
							}}
						>
							Operation's Sign-In With Google
						</Button>
					</Stack>
				</Paper>
				
			</Grid>
		</>
	);
};

export default OpsLogin;
