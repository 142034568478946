import {
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
  Typography,
  Box,
  Divider
} from "@mui/material";
import * as React from "react";
import { green } from "@mui/material/colors";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  setUserEmail,
  setUserName,
  setSignInTime,
  setIsAdmin,
  setSessionSelectPinCodeAllData,
} from "../utils/sessionCheck";
import { auth, provider } from "../firebase.config";
import { signInWithPopup } from "firebase/auth";
import "firebase/firestore";
import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { firestore } from "../firebase.config";
import { useState } from "react";
import { SignUp } from "../DatabaseAPI/DatabaseQuery";
import NavBar from "./OldNavBar";
import PageFooter from "../footer/PageFooter";
import Logo1 from "../svgicons/ReUrjaLogo_v1.svg";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import ReCAPTCHA from "react-google-recaptcha";
import { useEffect } from "react";
import NumericInput from "../validation/NumericInput";
import PhoneNumber from "../validation/PhoneNumber";
import AlphanumericInput from "../validation/AlphanumericInput";


const paperstyle = {
  padding: 20,
  // height: "80vh",
  maxWidth: 460,
  margin: "20px auto",
  border: "2px solid #07b307",
};

const CustomerSignUp = () => {
  const navigate = useNavigate();


  const [usrName, setusrName] = useState("");
  const [usrMobileNo, setusrMobileNo] = useState("");
  const [custDefaultPincode, setCustDefaultPincode] = useState(""); 
  const [custDefaultAddress, setCustDefaultAddress] = useState("");
  const [usrRoleGrp, setUsrRoleGrp] = useState("CustomerGrp");

  const [message, setMessage] = useState("");
  const [inputPincode, setInputPincode] = useState("");

  const[usrLastLogInDateAndTime, setUsrLastLogInDateAndTime]=useState(Date());

  //Set ReCaptcha validation state
  const sitekey1 = process.env.REACT_APP_RECAPCTHA_SITE_KEY;
  const [isHumanReCaptcha, setIsHumanReCaptcha] = useState(false);
  
  function onReCaptchaChange(value) {
		if(value !== "NaN") {
      setIsHumanReCaptcha(true);
	}
}

  


const getPincodeDetails =async () =>{
  const tempPinCdArray = []; 
  // console.log("Pincode: " + selectedPinCode)
  try {
    const pincodeCollection = collection(firestore, "PinCode_Master");
    const userpincode = parseInt(inputPincode, 10);
    const selectedPinCode = userpincode;
    const q = query(pincodeCollection, where("pinCodes", "==", userpincode)); 
    const querySnapshot = await getDocs(q);
    // console.log("Fetching data for pincode:", selectedPinCode);	
    // console.log("Number of documents found: ", querySnapshot.size);
    querySnapshot.forEach((doc) => {
      const pinCodeRecord = {
      pincode: doc.data().pinCodes,
      successPartnerName: doc.data().successPartnerName,
      successPartnerAddress: doc.data().successPartnerAddress,
      successPartnerEmailID1: doc.data().successPartnerEmailID1,
      successPartnerEmailID2: doc.data().successPartnerEmailID2,
      successPartnerMobileNo: doc.data().successPartnerMobileNo,
      successPartnerSPGrpID: doc.data().spGrpID,
  };
    tempPinCdArray.push(JSON.stringify(pinCodeRecord));
    });
    
    // Store the fetched data in the session or any state management
    setSessionSelectPinCodeAllData(tempPinCdArray);
    // console.log("Session Pincode Data:",cd)
    
    // console.log("All data fetched for the selected pincode:", tempPinCdArray);
  } catch (error) {
    console.error("Error fetching pincode details:", error);
  }
}



//Pincode check function whether available in specifc city or not then only user can signup 

async function checkPincode() {
  const userpincode = parseInt(inputPincode, 10);
  if (isNaN(userpincode) || inputPincode.length !== 6) {
    alert("Please enter a valid 6-digit Pincode");
    return;
}
  const pincodeCollection = collection(firestore, "PinCode_Master");
  const q = query(pincodeCollection, where("pinCodes", "==", userpincode)); 
  const querySnapshot = await getDocs(q);
  // console.log("Query Snap: " + querySnapshot.size);
  if (!querySnapshot.empty) {
    // const temparr2 = JSON.parse(temparr);
    // setSessionSelectPinCodeAllData(temparr);
    const doc = querySnapshot.docs[0];
    const pinCode = doc.data().pinCodes;
    setMessage("Pincode is available!");
    console.log("Pincode  found");
    return doc.data();
  } else {
    console.log("Pincode not found");
    setMessage("Pincode is not available.");
    return null;
  }
}


//User valid signUp function whether user is valid or not using 


let userEmail ="";
const logGoogleUser = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    userEmail = result.user.email;
    console.log(userEmail);
    return true;

  } catch (error) {
    console.error("Popup unexpectedly closed or error occurred: ", error);
    return false;
  }
};
//handle submit button which only register user when user is valid and used recaptch.
  const handleSubmit = async (e) => {
    
    e.preventDefault();
    
    
    try {
      // const userRef = await firestore.collection("Rnergy").doc();
      console.log("ReCaptcha value is >>", isHumanReCaptcha);
      


      if(!isHumanReCaptcha ){
        console.log("Please validate the ReCaptcha first >>");
        return;
      }
      const isGoogleUserValid = await logGoogleUser();

      if (!isGoogleUserValid) {
        console.log("Google user validation failed.");
        return;
      }

     const cd =  await checkPincode();
      if(cd === null)
      {
        console.log("Invalid Pincode");
        
        return ;
        
      }

      

        // console.log(userEmail);
        // setusrEmailID(userEmail);
        
        console.log(userEmail);
  
        
        const data = {
          UserName: usrName,
          Email: userEmail,
          PhoneNumber: usrMobileNo,
          DefaultCustomerAddress: custDefaultAddress,
          UserGrp: usrRoleGrp,
          UserLastLoggedInDateAndTime:usrLastLogInDateAndTime,
          DefaultCustomerPincode: inputPincode,
          
        };
        const signUpSuccess = await SignUp(userEmail,data);
        
        console.log("User added to Firestore", signUpSuccess);
        
        if(signUpSuccess){
          navigate("/login");
        }
        else {
          console.error("Sign-up failed.");
        }
      
      
      
      // Optionally, redirect user or show a success message
    } catch (error) {
      console.error("Error adding user: ", error);
      // Handle error, show error message, etc.
    } 
  }
//START change functuon that carry out value for changed values
  // const [inputValue, setInputValue] = useState('');
  const handleValueChange = (value) => {
    setInputPincode(value);
   
};

const handleValueChange2 = (value) => {
  setusrMobileNo(value);
 
};

const handleChangeName = (value) =>{
  setusrName(value);
};

//END
  return (
    <>
      <Box>
        <Box
          height={80}
          // width={200}
          // my={4}
          mt={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          p={2}
        // sx={{ border: "2px solid grey" }}
        >

          <Box
            // component="section"
            component="img"
            alt="ReUrja.com"
            src={Logo1}
            sx={{
              // py: 3,
              // px: 8,
              // // mx: 3,
              // m:3,
              // // p:10,
              // bgcolor: "#25d911",
              // border: "1px solid green",
              maxHeight: "50",
              maxWidth: "90",
              // minHeight: "40%",
              // minWidth: "40%",
            }}
          ></Box>
          <Box component="section" display="flex" sx={{ p: 2 }}>
            {/* <LocationOnIcon sx={{ p: 0, m: 0 }} /> */}
            {/*
            <Button>
              <Typography
                onClick={() => navigate("/signup")}
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
              >
                Login/ Sign-up
              </Typography>
          </Button>
        */}
          </Box>
        </Box>
      </Box>
      <Divider variant="middle" sx={{ m: 1, p: 1, borderBottomWidth: 5 }}></Divider>

        {/* signup page code */}
        <Grid>
          <Paper elevation={10} style={paperstyle}>
            <Grid align="center">
              <Avatar sx={{ bgcolor: green[500] }}>
                <LockIcon></LockIcon>
              </Avatar>
              <Typography variant="h4">Sign up</Typography>
            </Grid>
            <Grid>
              <Box
                component="form"
                // onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                

              <AlphanumericInput 
                
                onValueChange={handleChangeName} 
                label="Enter name"
                />
                

                <TextField
                  margin="normal"
                  id="custAddrress1"
                  label="Default Delivery Address"
                  variant="outlined"
                  name="custAddr1"
                  placeholder="Enter Delivery Address"
                  value={custDefaultAddress}
                  onChange={(e) => setCustDefaultAddress(e.target.value)}
                  fullWidth
                  required
                  multiline
                  sx={{mb:3}}
                />


            <NumericInput
                allowDecimals={false}
                allowNegative={false}
                min={0}
                max={1000000}
                onValueChange={handleValueChange}
            />
               
                <h5 style={{color:'red'}}>We Currently Serve in Pune City Only </h5>
              
              

            
              
              <PhoneNumber
                allowDecimals={false}
                allowNegative={false}
                min={0}
                max={10000000000}
                onValueChange={handleValueChange2}
                maxLength={10}
              />
                
{/* 
                Below component can be used for verifying the phone number upon entering
                It's temporarily commented and replaced with a Textfield based Phone Number */}

                {/* <PhoneSignIn/> */}

                <ReCAPTCHA
						    	sitekey={sitekey1}
							    onChange={ onReCaptchaChange}
						    />

              <Button
                sx={{ bgcolor: green[500], mt: 2, mb: 1 }}
                variant="contained"
                fullWidth
                type="submit"
                onClick={handleSubmit}
                disabled={!isHumanReCaptcha}
              >
                Sign up with Google
              </Button>
              

              
            </Box>
          </Grid>
          
        </Paper>
      </Grid>
      <PageFooter />
    </>
  );
}

export default CustomerSignUp;
