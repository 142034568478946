import * as React from "react";
import Box from "@mui/material/Box";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";
import { useState } from "react";
import {
	Alert,
	Button,
	Snackbar,
	Tooltip,
} from "@mui/material";
import { Menu } from "@mui/material";
import { MenuItem } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { CartState } from "../context/ShopContext";
import {
	getUserEmail,
	clearAllSessionObjects,
	getUserDisplayName,
} from "../utils/sessionCheck";
import IconButton from "@mui/material/IconButton";
import Logo1 from "../svgicons/ReUrjaLogo_v1.svg";
import HomeIcon from "@mui/icons-material/Home";
import { useEffect } from "react";
import { getSessionDeliveryPinCode } from "../utils/sessionCheck";
import PincodePopup from "./PincodePopup";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import LoginIcon from '@mui/icons-material/Login';
import { green, lightGreen } from "@mui/material/colors";
import { Logout } from "@mui/icons-material";

// TODO remove, this demo shouldn't need to reset the theme.
// const defaultTheme = createTheme();
export default function NavBar2(props) {
	const [selectedPinCode, setSelectedPinCode] = useState("");
	const [open, setOpen] = useState(false);
	const [displayName, setDisplayName] = useState("");
	//Snackbar handling
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [severity, setSeverity] = useState('');


	const [anchorEnquiry, setAnchorEnquiry] = useState(null);
	const navigate = useNavigate();

	const { cart, setCart, updateProducts } = CartState();//cart instance called through context api
	const totalItemsInCart = cart.reduce((total, item) => total + item.qty, 0);//Updating count of cart

	//function to navigate to home
	const handleHomeClick = () => {
		navigate("/home");
	};


	const handleEnquiryMenuClick = (event) => {
		setAnchorEnquiry(event.currentTarget);
	};
	const handleEnquiryMenuClose = () => {
		setAnchorEnquiry(null);
	};


	//useNavigate is the hook provided for redirection
	//function to navigate to orders of customer
	const handleTrackOrderClick = (event) => {
		navigate("/orders");
	};


	//function to navigate to knowBs of customer
	const handleKnowledbaseClick = () => {
		navigate("/knowBs");
	};


	// const handleEnquiryClick = (type) => {
	// 	if(!getUserEmail()){
	// 		navigate("/enq", { state: { formType: type } });
	// 	}
		
		
	// };
	const handleEnquiryClick = (type) => {
		navigate("/enq", { state:{formType:type} });
	};
	
//logout function which simply clearsession objects as well as empty cart and navigate to home page
	const handleLogout = () => {
		clearAllSessionObjects();
		updateProducts([]);
		setCart([]);
		navigate("/");
	};

	useEffect(() => {
		// Function to fetch and set pincodes
		let cd = getUserDisplayName();
		// console.log("Display Name:", cd);
		setDisplayName(cd);
		// console.log("Display Name:", displayName);
		let cd1 = getSessionDeliveryPinCode();
		setSelectedPinCode(cd1);
		// console.log("Selected Pincode:", selectedPinCode);
	}, [displayName, selectedPinCode]);

	const handleClickAway = () => {
		setOpen(false);
	};

	const style = {
		color: "#127008",
		fontWeight: "bold",
		position: "absolute",
		left: "75%",
		top: "25px",
	};
	const style2 = {
		color: "#127008",
		fontWeight: "bold",
	};
	
	// Start handles snack bar open and close functionality through states
	const handleSnackbarOpen = () => {
		setSnackbarOpen(true);
	  };
	  const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	  };
	  //END

	  //checking for Cart data if cart has any object then redirecting to the cart else keeping user on home page 
	  const handlecart = () =>
	  {
		if(totalItemsInCart>0)
		{
			navigate('/checkout')
			return true;
		}
		else{
			setMessage("Cart is empty");
			setSeverity("error");
			handleSnackbarOpen();
			return false;
		}
	  }

	//return Statement conatains all elements which are present in navbar
	return (
		<Box>
			<Box
				height={30}
				mt={1}
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				gap={4}
				p={2}
				// sx={{ border: "2px solid grey" }}
			>
				<Link to={"/home"}>
					{/* <img */}
					<Box
						// component="section"
						component="img"
						alt="ReUrja.com"
						src={Logo1}
						sx={{
							height: "55%",
							width: "55%",
						}}
					></Box>
					{/* </img> */}
				</Link>
				<Box component="section" display={props.pinCodeBoxDisplay} sx={{ p: 2 }}>
					{getSessionDeliveryPinCode() ?( <Button onClick={() => setOpen(true)} variant="text" sx={style}>
						Pincode: {selectedPinCode}
					</Button>): (<Typography></Typography>)}
					{/* Integrating PincodePopup component */}
					{open && (
						<ClickAwayListener onClickAway={handleClickAway}>
							<PincodePopup
								trigger={open}
								setTrigger={setOpen}
								setSelectedPinCode={setSelectedPinCode}
							/>
						</ClickAwayListener>
					)}
				</Box>

				 {getUserEmail() ? (

					
          <Box component="section" display="flex" gap={2} sx={{ p: 2 }}>
            {" "}
            <Tooltip title="View Cart">
            {/* <IconButton aria-label="delete" size="large" sx={{display:cartDisplay}}> */}
            <IconButton aria-label="delete" size="large" sx={{display:props.cartDisplayProp}}>
				<div onClick={handlecart}>
					<Link>
					<Badge badgeContent={totalItemsInCart}  color="success">
                  <ShoppingCartIcon  size="large"></ShoppingCartIcon>
                </Badge>
					</Link>
				
				</div>
				{/* <Link to={"/checkout"} >
                
              </Link> */}

              <Snackbar
					open={snackbarOpen}
					autoHideDuration={3000}
					onClose={handleSnackbarClose}
					variant="filled"
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				>
					 <Alert
						onClose={handleSnackbarClose}
						severity={severity} 
						variant="filled"
						sx={{ width: '100%' }}
						>
						{message}
						</Alert>


				</Snackbar>
            </IconButton>
            </Tooltip>
            <Tooltip title="Logout">
            <IconButton aria-label="logout" size="large" 
			onClick={handleLogout}>
              {/* <ExitToAppIcon /> */}
			  <Logout />
            </IconButton>
            </Tooltip>
		
          </Box>

		  
        ) : (
          <Box>
			<Tooltip title="Login">
			<IconButton sx={{backgroundColor:green[100]}}
				onClick={()=>{
					navigate("/login")
				}
				}
			>
				<LoginIcon></LoginIcon>
				
            	{/* <Link to="/login">LogIn</Link>  */}
			</IconButton>
			</Tooltip>

			{/* <Button variant="contained" sx={{borderRadius:8, backgroundColor:"orange", color:"darkgreen"}}
				onClick={()=>{
					navigate("/custsignup")
				}
				}
			>
				SignUp            	
			</Button> */}
				{/* <Link to="/login">LogIn</Link>  */}
			{/* <Link to="/signup">SignUp</Link> */}
          </Box>
        )}
			</Box>

			{/* <Divider variant="middle" sx={{ p: 1, borderBottomWidth: 2, bgcolor:"green" }}></Divider> */}
			<Divider
				variant="middle"
				sx={{ bgcolor: "green", borderBottomWidth: "2px", mt: 2 }}
			></Divider>
			
			<Box
				height={10}
				// width={200}
				// my={4}
				mt={1}
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				gap={4}
				// p={2}
				pt={1}

				// sx={{ border: "2px solid grey" }}
			>
				<Toolbar sx={{ display: props.showMenuBarProp }}>
					<Button
						color="inherit"
						variant="text"
						onClick={handleHomeClick}
						sx={{ color: "#f79646", fontWeight: "bold" }}
						startIcon={<HomeIcon />}
					>
						Home
					</Button>
					

					
				
				<Typography sx={{ color: "green" }}>|</Typography>
					<Button
					color="inherit"
					variant="text"
					onClick={handleEnquiryMenuClick}
					sx={{ color: "#127008", fontWeight: "bold" }}
				>
					Enquiry Forms{" "}
					<Typography variant="caption" sx={{ color: "orange" }}>
						{" "}
						&nbsp;v
					</Typography>
				</Button>
				
					<Menu
						anchorEl={anchorEnquiry}
						open={Boolean(anchorEnquiry)}
						onClose={handleEnquiryMenuClose}
					>
						<MenuItem onClick={() => handleEnquiryClick("On Grid Solar")}>
							On Grid Solar
						</MenuItem>
						<MenuItem onClick={() => handleEnquiryClick("Green Open Access")}>
							Green Open Access
						</MenuItem>
						<MenuItem onClick={() => handleEnquiryClick("Off Grid Solar")}>
							Off Grid Solar
						</MenuItem>
						<MenuItem onClick={() => handleEnquiryClick("Solar Plant Project Management")}>
							Project Management
						</MenuItem>
						<Divider/>
						
						<MenuItem onClick={() => handleEnquiryClick("AMC for Battery")}>
							Battery AMC
						</MenuItem>
						<MenuItem onClick={() => handleEnquiryClick("AMC for Solar Plant")}>
							Solar AMC
						</MenuItem>
						<MenuItem onClick={() => handleEnquiryClick("AMC for Diesel Generator Set")}>
							DG Set AMC
						</MenuItem>
						{/* <MenuItem onClick={() => handleEnquiryClick("AMC for CCTV System")}>
							CCTV AMC
						</MenuItem> */}
						<Divider/>

						<MenuItem onClick={() => handleEnquiryClick("Bulk LED Requirements")}>
							LED
						</MenuItem>
						<MenuItem onClick={() => handleEnquiryClick("Automation Requirements")}>
							Automation
						</MenuItem>
						{/* <MenuItem onClick={() => handleEnquiryClick("Carbon Credit Requirements")}>
							CO2 Credits
						</MenuItem> */}
						<Divider/>

						<MenuItem onClick={() => handleEnquiryClick("Energy Audit")}>
							Energy Audit
						</MenuItem>
						<MenuItem onClick={() => handleEnquiryClick("Green Energy Consultancy")}>
							Green Energy Consultancy
						</MenuItem>
						<MenuItem onClick={() => handleEnquiryClick("Training Requirements")}>
							Training
						</MenuItem>
					</Menu>
					<Typography sx={{ color: "green" }}>|</Typography>
					<Button
						color="inherit"
						variant="text"
						onClick={handleTrackOrderClick}
						sx={{ color: "#127008", fontWeight: "bold" }}
					>
						Track Orders
					</Button>

					<Typography sx={{ color: "green" }}>|</Typography>

					<Button
						variant="text"
						sx={{ color: "#127008", fontWeight: "bold" }}
						onClick={handleKnowledbaseClick}
					>
						Knowledgebase
					</Button>
					<Box />
					{/* <Typography sx={{ color: "green" }}>|</Typography>

					<Button
						variant="text"
						sx={{ color: "#127008", fontWeight: "bold" }}
						onClick={() => handleEnquiryClick("Contact Us")}
					>
						Contact Us
					</Button>
					<Box /> */}
					<Typography sx={{ color: "green" }}>|</Typography>

				</Toolbar>
				{/* Pincode section */}

				<Box
					component="section"
					display="flex"
					alignItems="center"
					justifyContent="flex-end"
					sx={{ p: 2, mt: 1, mb: 1 }}
				>
					{getUserEmail() ? 
					(
							(props.isAdmin || props.isOps) ? 
							(
									props.isAdmin ? (
														<Typography variant="body2" sx={style2}>
																		Welcome, &nbsp; Admin
																	</Typography>
							)
							:(
											<Typography variant="body2" sx={style2}>
											Welcome, &nbsp; Ops
										</Typography>
						)):(
							<Typography variant="body2" sx={style2}>
							Welcome, &nbsp;{displayName}
							</Typography>
						)
						) : (
						<Typography></Typography>
					)}
				</Box>
			</Box>
			{/* <Divider variant="middle" sx={{ p:1, borderBottomWidth:5, mb:1 }}></Divider> */}
			<Divider
				variant="middle"
				sx={{ bgcolor: "green", borderBottomWidth: "2px", mt: 2, mb: 1 }}
			></Divider>
		</Box>
		// </AppBar>
	);
}
