import * as React from 'react';
//import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { Stack } from '@mui/system';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
//import WhatsAppIcon from "../svg/whatsapp-svgrepo-com.svg" ;
import {ReactComponent as WhatsAppReactLogo} from "../svgicons/whatsapp-svgrepo-com.svg"
import { IconButton, Tooltip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { LinkedIn } from '@mui/icons-material';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';



export default function PageFooter() {

    //const footNavi = useNavigate(); // used for handling the routes

    return (
        <>
        <Box sx={{ flexGrow: 1 }}>

        {/* draw a header/footer line component */}
        <Box sx={{ bgcolor: "lightGreen", 
            flexGrow: 1, border: 1, 
            height: '0.1rem', width: 'auto'}} />
        <br />
        
          <Grid container 
        //   minHeight={20}
          
          spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid xs={2} sm={4} md={4} display="flex" justifyContent="center">
              <div>
                    
                    <Box component="ul" aria-labelledby="category-a" sx={{ pl: 0 }}>
                        <ol style={{ listStyleType: 'square', 
                                    color: 'primary.main' }}>
                            <li><Link to='/aboutus'> About Us </Link></li>
                        </ol>
                    </Box>
                </div>
            </Grid>
            {/* <Grid xs={2} sm={4} md={4} display="flex" justifyContent="center" >
            <div>
                    <Box><b>Membership</b></Box>
                    <Box component="ul" aria-labelledby="category-a" sx={{ pl: 0 }}>
                        <ol style={{ listStyleType: 'square', 
                                    color: 'primary.main' }}>
                            <li><Link to='/membership'>Benefits</Link></li>
                            <li><Link to='/membership'>Professional</Link></li>
                            <li><Link to='/membership'>Students</Link></li>
                        </ol>
                    </Box>
                </div>
            </Grid> */}
            <Grid xs={2} sm={4} md={4} display="flex" justifyContent="center" >
                <div>
                        {/* <Box><b>Contact Us</b></Box> */}
                        <Box component="ul" aria-labelledby="category-a" sx={{ pl: 0 }}>
                            <ol style={{ listStyleType: 'square', 
                                        color: 'primary.main' }}>
                                <li><Link to='/contactus'>ContactUs</Link></li>
                                {/* <li><Link to='/'>Address</Link></li> */}
                            </ol>
                        </Box>
                </div>
            </Grid>
            <Grid xs={2} sm={4} md={4} display="flex" justifyContent="center" >
                <div>
                        {/* <Box><b>Contact Us</b></Box> */}
                        <Box component="ul" aria-labelledby="category-a" sx={{ pl: 0 }}>
                            <ol style={{ listStyleType: 'square', 
                                        color: 'primary.main' }}>
                                <li><Link target='_blank' to='/tnc'>Terms & Privacy Policy</Link></li>
                                {/* <li><Link to='/'>Address</Link></li> */}
                            </ol>
                        </Box>
                </div>
            </Grid>
            {/* <Grid xs={2} sm={4} md={4} display="flex" justifyContent="center" >
                <div>
                         {/* open the TnC page in browser window using target option*/}
                        {/* <Box><b><Link to='/TnC' target='_blank'>   Terms and Privacy Policy</Link></b></Box> */}
                        {/* <Box><Link to='/tnc'>Terms & Privacy Policy</Link></Box>
                        
                </div>
            </Grid> */}
            <Grid xs={2} sm={4} md={4} display="flex" justifyContent="center" marginLeft={15}>
                <div>
                    <Box><b>Social Media:</b>
                    {/* <Stack direction="row" > */}
                        <IconButton href="https://www.linkedin.com/company/reurja" target="_blank" 
                    rel="noopener noreferrer">
                            <LinkedIn color={'primary'} fontSize={'large'}/>
                        </IconButton>

                        <IconButton href="https://www.youtube.com/@Reurja" target="_blank" 
                    rel="noopener noreferrer">
                            <YouTubeIcon color={'error'} fontSize={'large'} />
                        </IconButton>
                    {/* </Stack> */}
                        
                    {/* <Stack direction="row" > */}

                        <IconButton href="https://www.facebook.com/groups/Reurja.com" target="_blank" 
                    rel="noopener noreferrer">
                            <FacebookIcon color={'primary'} fontSize={'large'}/>
                        </IconButton>

                        <IconButton href="https://wa.me/9422652285" target="_blank" 
                    rel="noopener noreferrer">
                            <WhatsAppReactLogo/>
                        </IconButton>
                        
                      
                    {/* </Stack> */}
                    </Box>
                </div>
            </Grid>
            <Grid></Grid>
            <Grid></Grid>
            <Grid></Grid>
            <Grid></Grid>
            <Grid></Grid>
            <Grid>
                <Box display="flex" justifyContent="flex-end">
                <IconButton>
                            <Link to={"/9f5cb"}>
                                <Tooltip title="SPLogin">
                                    <ContactEmergencyIcon />
                                </Tooltip>
                            </Link>
                        </IconButton>
                </Box>
            </Grid>
          </Grid>

            <Box ml={3}>
            <p>
                <Typography variant="h11" align="left" fontSize={10}
                textAlign="center"><b><u>Disclaimer: </u></b>
                
                Any/all trademarks/names featured on this Website are owned by the respective trade mark owners. 
                Where a trade mark or brand name is referred to, it is used solely to describe or identify the products 
                and services and is in no way an assertion that such products or services are endorsed by or connected 
                to this website.
                </Typography>

                {/* Brands showcased on this website are registered trademarks of those organizations. 
                This website does not claim. */}

                </p>
            </Box>

   

          {/* draw a header/footer line component */}
        <Box sx={{ bgcolor: 'lightGreen', 
            flexGrow: 1, border: 1, 
            height: '0.1rem', width: 'auto'}} />
        <br />
        
        </Box>

        <div>
            <Box display="flex" justifyContent="flex-end">
                <div>
                    <Typography fontSize={10}
                        sx={{mr:3}}>Copyright @2024-25 ReUrja.com</Typography>
                </div>
            </Box>
            <Box display="flex" justifyContent="flex-end">
            <div>
                <Typography fontSize={10}
                    sx={{mr:3}}>Powered by <a href="https://www.omnevu.com" target="_blank" 
                    rel="noopener noreferrer">OmneVu.com</a></Typography>
            </div>
            </Box>

           
        </div>
        </>
      );
}