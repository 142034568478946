import * as React from "react";
import { getSessionDeliveryPinCode, getUserEmail } from "../utils/sessionCheck";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Alert,
  CardActionArea,
  CardActions,
  Divider,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ProdListImg from "../images/batteryImg.PNG";
import { useNavigate, Link } from "react-router-dom";
import { CartState } from "../context/ShopContext";
import BreadCrumbs from "./breadCrumbs";
import Snackbar from '@mui/material/Snackbar';
import { useState } from "react";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { isProductAvailableAtPincode } from "../DatabaseAPI/DatabaseQuery";
import { useEffect } from "react";
import { lightGreen } from "@mui/material/colors";

// import UserAlert from "./UserAlert";

export default function ProductThumbNlCard({ prod }) {
  const { cart, setCart, prodDetailID, setProdDetailID } = CartState();
  const navigateTo = useNavigate();
  // console.log("cart==", cart);
  // const ac="9ff7";

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState("");
  

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  
  const [deliveryPin, setDeliveryPin] = useState();
  

  const handleAddToCart = async () => {
    try {
      // Await the result of the async function
      const isAvailable = await isProductAvailableAtPincode(prod.prodID, Number(getSessionDeliveryPinCode()));
      console.log(isAvailable); // This will now be the correct value
      
      if (!isAvailable) {
        // alert('Product is not deliverable at your given PinCode');
        setMessage("Product is not deliverable at your given PinCode");
			setSeverity("warning");
			handleSnackbarOpen();
        return; // Exit the function if the product is not available
      }
  
      // Check if the product is already in the cart
      if (getUserEmail() == null) {
        alert("Please login first");
      } else {
        const existingProductIndex = cart.findIndex(
          (item) => item.prodID === prod.prodID
        );
  
        if (existingProductIndex !== -1) {
          // If the product is already in the cart, update its quantity
          const updatedCart = [...cart];
          updatedCart[existingProductIndex].qty++;
          setCart(updatedCart);
          setMessage("Item added to cart");
          // Show the Snackbar
          handleSnackbarOpen();
        } else {
          // If the product is not in the cart, add it
          setCart([...cart, { ...prod, qty: 1 }]);
          setMessage("Item added to cart");
          // Show the Snackbar
          handleSnackbarOpen();
        }
      }
    } catch (error) {
      console.error('Error adding product to cart:', error);
    }
  };

/* handlebuynow added*/
  const handleBuyNow = async () =>
    {
      try {
        const isAvailable = await isProductAvailableAtPincode(prod.prodID, Number(getSessionDeliveryPinCode()));
  
        if (!isAvailable) {
          alert('Product is not deliverable at your given PinCode');
          return; // Exit the function if the product is not available
        }
  
        if (getUserEmail() == null) {
          alert("Please login first");
        } else {
          const existingProductIndex = cart.findIndex(
            (item) => item.prodID === prod.prodID
          );if (existingProductIndex !== -1) {
            // If the product is already in the cart, update its quantity
            const updatedCart = [...cart];
            updatedCart[existingProductIndex].qty++;
            setCart(updatedCart);
          } else {
            // If the product is not in the cart, add it
            setCart([...cart, { ...prod, qty: 1 }]);
          }
          navigateTo("/checkout");
        }
  
        
      } catch (error) {
        console.error('Error adding product to cart:', error);
      }
    }



 
  return (
    <Card sx={{ maxWidth: 345, m: 2, maxHeight: 400, border:1, borderColor:'lightgreen' }}>
      
      <CardActionArea
        onClick={() => {
          setProdDetailID(prod.prodID);
          navigateTo("/ProdDtls");
        }}
      >
        
        <CardMedia
          component="img"
          height='80'
          maxHeight='82'
          image={prod.prodImageThumbnailLoc}
          alt="battery list"
          //   sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
          sx={{ objectFit: "contain" }}
        />

        <CardContent>
          <Typography gutterBottom variant="h6" component="div" sx={{color:'red'}}>
            {prod.prodName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {/* <b>Index:</b> {prod.index}
            <br></br>
            <b>Type:</b> {prod.prodUsageType}
            <br></br> */}
            <b>Product Code:</b> {prod.prodTechName}
            <br></br>
            <b>Warranty: </b> {prod.prodWarrantyDtls}
            <br></br>
            <b>MRP: </b> ₹{prod.prodMRP}
            <br></br>
            <b>Offer Price(OP): </b> ₹{prod.prodOP1WithGST}
            <br></br>
            {/* <b>Offer Price with Exchange: </b> ₹{prod.prodOP1WithGST- prod.prodIndicativeExchngPrice} */}
            <b>Exchange Price </b>(<i>same Ah & Type</i>):  ₹{prod.prodIndicativeExchngPrice}
            <br/>
            <b>Capacity: </b> {prod.prodAmpereHourRating}
          </Typography>
        </CardContent>
      </CardActionArea>
      <Divider></Divider>
      <CardActions sx={{alignItems:'center', justifyContent:'center'}}>
        <Stack direction="row" spacing={4} ml={2}>
          <Tooltip title="Add to Shopping cart">
            <IconButton
              color="success"
              aria-label="Add to shopping cart"
              onClick={handleAddToCart}
            >
              <AddShoppingCartIcon />
            </IconButton>
          </Tooltip>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000} // Adjust as needed
            onClose={handleSnackbarClose}
            message={message}
            severity="success"
            variant="filled"
            // sx={{backgroundColor:'lightgreen', color:'red'}}
            anchorOrigin={{vertical: 'botton', horizontal: 'center'}}
        />

      <Snackbar
					open={snackbarOpen}
					autoHideDuration={3000}
					onClose={handleSnackbarClose}
					variant="filled"
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				>
					 <Alert
						onClose={handleSnackbarClose}
						severity={severity} 
						variant="filled"
						sx={{ width: '100%' }}
						>
						{message}
						</Alert>


				</Snackbar>
          <a href="tel:9422652285" style={{ textDecoration: "none" }}>
            <Tooltip title="Call for Best Price">
              <IconButton
                color="error"
                aria-label="Call for Best Prices"
              // onClick={() => {
              //   alert("Call for Best Price");
              // }}
              >
                <LocalPhoneIcon />
                {/* <Typography variant='subtitle2'>Best Price</Typography> */}
              </IconButton>
            </Tooltip>
          </a>
          <Tooltip title="Buy Now">
            <IconButton
              color="primary"
              aria-label="Buy Now"
              onClick={handleBuyNow}
            >
              <ShoppingBasketIcon />
              {/* <Typography variant='subtitle2'>Best Price</Typography> */}
            </IconButton>
          </Tooltip>
        </Stack>
        {/* <BreadCrumbs /> */}
      </CardActions>
            
    </Card>
  );
}
