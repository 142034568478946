import {
  Grid,
  Paper,
  Avatar,
  // TextField,
  Button,
  Typography,
  // Box,
  Stack,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
// import { green } from "@mui/material/colors";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";
import {
  setUserEmail,
  setIsAdmin,
  setIsOps,
  getIsOps,
  getIsAdmin,
  // setLast,
} from "../utils/sessionCheck";
import { auth, provider, firestore } from "../firebase.config";
//   import { collection, query, where, getDocs } from "firebase/firestore";
import { signInWithPopup } from "firebase/auth";
import Divider from "@mui/material/Divider";
import { useEffect } from "react";
import Logo1 from "../svgicons/ReUrjaLogo_v1.svg";
import backImg from "../images/backgroundImg.png";

const paperstyle = {
  padding: 20,
  height: "30vh",
  width: 460,
  margin: "20px auto",
};

const LandingPage = () => {
  const navigate = useNavigate();

  useEffect(() => {

    // what for blow lines of code is getting used for 

    if (getIsOps("IsOps") || getIsAdmin("IsAdmin")) {
      navigate("/home");
    }

    // get current dployment env from env file. If the environment value os "prod" then do not show regular 
    // landing page available at route "/" and move directly to "/home" page
    const currDeploymentEnv = process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT;

    if(currDeploymentEnv == 'prod'){
      // console.log("Current deployment env is", currDeploymentEnv);
    
      navigate("/home");

    }

  }, []);

  const logGoogleUser = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // setLast(0);
    // console.log(data.get("pass"));
    try {
      // const pin = "RnergyProject28";
      const pin = "28";
      if (pin === data.get("pass")) {
        navigate("/home");
      }
    } catch (error) {
      console.error("Error in finding the user: ", error);
      navigate("/");
    }
  };

  const myStyle = {
    //backgroundImage: "url(../images/)",
    
    height: "100vh",
    marginTop: "-70px",
    fontSize: "50px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
};

  return (
    <Box>
      <Box
        height={80}
        // width={200}
        // my={4}
        mt={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={4}
        p={2}
        // sx={{ border: "2px solid grey" }}
      >
        {/* <Box
          component="section"
          sx={{
            py: 2,
            px: 8,
            mx: 3,
            bgcolor: "#25d911",
            border: "1px solid green",
          }}
        >
          App Icon
        </Box> */}

        <Box
            // component="section"
            component="img"
            alt="ReUrja.com"
            src={Logo1}
            sx={{
              // py: 3,
              // px: 8,
              // // mx: 3,
              // m:3,
              // // p:10,
              // bgcolor: "#25d911",
              // border: "1px solid green",
              maxHeight: "50",
              maxWidth: "90",
              // minHeight: "40%",
              // minWidth: "40%",
            }}
          ></Box>
        <Box component="section" display="flex" sx={{ p: 2 }}>
          {/* <LocationOnIcon sx={{ p: 0, m: 0 }} /> */}
          {/*
          <Button>
            <Typography
              onClick={() => navigate("/signup")}
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Login/ Sign-up
            </Typography>
          </Button>
        */}
        </Box>
      </Box>
      <Divider variant="middle" sx={{ m:1,p: 1, borderBottomWidth: 5 }}></Divider>

      <Stack display="flex" alignItems='center'>

      <Typography variant="h3" sx={{color:"green", m:3}}> We are just getting Ready to come to your service soon. 

      Thanks for visiting here!!</Typography>
      
      <Box component="img" src={backImg}></Box>

      </Stack>

      <Grid
        container
        justifyContent="center"
        alignContent={"center"}
        sx={{ height: "100vh" }}
      >
        <Paper
          elevation={10}
          style={paperstyle}
          sx={{ height: "20%", bgcolor: "#79f299" }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
            display={"flex"}
            flexDirection={"column"}
            alignItems="center"
            onSubmit={logGoogleUser}
          >
            <Avatar sx={{ bgcolor: "green" }}>
              <LockIcon />
            </Avatar>
            {/* <Typography variant="h4">Password</Typography> */}
            <TextField
              id="password"
              label="Enter Password"
              variant="outlined"
              name="pass"
            />
            <Button
              type="submit"
              sx={{ bgcolor: "green", width: "60%", mt: 4 }}
              variant="contained"
              fullWidth
            >
              Submit
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Box>
  );
};

export default LandingPage;
