// CheckoutPage.jsx
import React, { useEffect, useState } from "react";
import {
  Paper,
  Button,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  Box,
  TextField,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import PageFooter from "../footer/PageFooter";
import {
  OrderListCustomer,
  LoadMoreOrderListCust,
  OrderItem,
  EditOrder,
  UpdateOrder,
} from "../DatabaseAPI/DatabaseQuery";
import {  getUserEmail } from "../utils/sessionCheck";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import NavBar2 from "../components/Navbar2";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";


const OrderProcessingCustomer = () => {
  useEffect(() => {
    if(!getUserEmail()){
      navigate('/login')
    }
  })

  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const emailID = getUserEmail();


  const CreateList = async () => {
    const productList = await OrderListCustomer(getUserEmail());

    if (productList){
      setOrders(productList);
      setLoadMore(true);
      setLoadMoreOption(true);
    } else {
      console.log ("Track order does not have anythig to return")
    }


  };

  useEffect(() => {
    CreateList();
  }, []);
  // const products = cart;

  // Show the success or failuer message as snackbar
  const [open, setOpen] = React.useState(false);
  //let ordAlertMsg = "Your Order Number - ";
  const [ordAlertMsg, setOrdAlertMsg] = React.useState("");
  const [msgType, setMsgType] = React.useState("success");
  const [loadMore, setLoadMore] = useState(false);
  const [loadMoreOption, setLoadMoreOption] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };


  const [searchValue, setSearchValue] = useState("");
  const [searchEmailValue, setSearchEmailValue] = useState("");


  const handleSearchClick = () => {
    setOrders(
      orders.filter((item) => {
        return (
          item.ordID.includes(searchValue) &&
          item.ordEmailID.includes(searchEmailValue)
        );
      })
    );
    setLoadMoreOption(false);
  };

  const handleSearchCancel = () => {
    window.location.reload();
  };

  const LoadData = async () => {
    try {
      const newCards = await LoadMoreOrderListCust();
      setOrders((prevCards) => [...prevCards, ...newCards]);

      if (newCards.length < 5) {
        setLoadMoreOption(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetOrder = async () => {
    if (orders.length === 0) {
      const productList = await OrderItem(searchValue, searchEmailValue);
      setOrders(productList);
      setLoadMoreOption(false);
    }
  };

  useEffect(() => {
    if (orders.length === 0 && loadMore) {
      GetOrder();
    }
  }, [orders]);
 

  const handleOrderChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleEmailChange = (event) => {
    setSearchEmailValue(event.target.value);
  };
  const statusOp = process.env.REACT_APP_STATUS.split(",");

  return (
    <>
      <NavBar2 cartDisplayProp={'none'}
              showMenuBarProp={'show'}
            />

<Box maxWidth="xl" height="auto" margin={2}>
        <Paper
      elevation={3}
      margin={2}
      style={{
        padding: "10px",
        margin: "auto",
        alignItem: "center",
        alignSelf: "center",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "10px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", width: "100%", }}>
        <TextField
          sx={{ flex: 1, marginRight: "20px",bgcolor: "#e8f5e9" }}
          id="orderSearch"
          label="Search Order ID"
          value={searchValue}
          onChange={handleOrderChange}
          variant="outlined"
        />
        <TextField
          sx={{ flex: 1, marginRight: "20px",bgcolor: "#e8f5e9" }}
          id="emailSearch"
          label="Search Email ID"
          value={searchEmailValue}
          onChange={handleEmailChange}
          variant="outlined"
        />
        <IconButton
          aria-label="search"
          size="medium"
          sx={{ marginRight: "10px", color: "#07b307" }}
          onClick={handleSearchClick}
        >
          <SearchIcon />
        </IconButton>
        <IconButton
          aria-label="cancel"
          size="medium"
          sx={{  color: "#ff4d4d" }}
          onClick={handleSearchCancel}
        >
          <CancelIcon />
        </IconButton>
      </Box>
    </Paper>
    </Box>
    
      <Box maxWidth="xl" height="auto" margin={2}>
        <Paper
          elevation={3}
          style={{
            padding: "10px",
            marginTop: "10px",
            border: "1px solid #07b307",
            height: "auto",
          }}
        >

      <Table>
        <TableHead>
          <TableRow style={{ backgroundColor: "#f5f5f5" }}>
            {["Order Reference No", "Order ID",  "Product Name","Amount", "Phone number", "Delivery Address", "Status"].map((header, index) => (
              <TableCell
                key={index}
                style={{ fontWeight: "bold", borderBottom: "2px solid #07b307", color: "#333", padding: "10px 15px", fontSize: '14px' }}
                align="center"
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <TableRow key={order.ordID} hover style={{ backgroundColor: "#fff", borderBottom: "1px solid #e0e0e0" }}>
              <TableCell align="center" style={{ padding: "10px 15px" }}>{order.ordReferenceID}</TableCell>
              <TableCell align="center" style={{ padding: "10px 15px" }}>{order.ordID}</TableCell>
              <TableCell align="center" style={{ padding: "10px 15px" }}>{order.ordProdName}</TableCell>
              <TableCell align="center" style={{ padding: "10px 15px" }}>
                <meta charSet="UTF-8" />₹{order.ordTotalAmountPayable}
              </TableCell>
              <TableCell align="center" style={{ padding: "10px 15px" }}>{order.ordPhoneNumber}</TableCell>
              <TableCell align="center" style={{ padding: "10px 15px" }}>{order.ordCustomerDeliveryAddress}</TableCell>
              <TableCell align="center" style={{ padding: "10px 15px", color: "#07b307", fontWeight: "bold" }}>
                {statusOp[order.ordOverallStatus - 1]}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
        </Paper>


        {/* </Container> */}
      </Box>

      {/* display the order number to user */}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "botton", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={msgType}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {/* This is a success Alert inside a Snackbar! */}
          {ordAlertMsg}
        </Alert>
      </Snackbar>

      {loadMoreOption && (
            <Box sx={{ m: 2, width: "100%" }}>
              <Button
                variant="contained"
                sx={{
                  px: "44%",
                }}
                onClick={LoadData}
              >
                <b>Load&nbsp;More... </b>
              </Button>
            </Box>
          )}
          <Divider style={{ margin: "20px" }} />

      <PageFooter />
    </>
  );
};

export default OrderProcessingCustomer;
